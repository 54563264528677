import React, { useEffect, useState } from 'react';
import '../styles/calculator.css'
import moment from 'moment-timezone';

const TimezoneTable = () => {
  const [timezones, setTimezones] = useState([]);
  const [filteredTimezones, setFilteredTimezones] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  useEffect(() => {
    const fetchTimezones = () => {
      const allTimezones = moment.tz.names();
      const timezoneData = allTimezones.map((timezone) => ({
        name: timezone,
        offset: moment.tz(timezone).utcOffset() / 60,
      }));
      setTimezones(timezoneData);
      setFilteredTimezones(timezoneData);
    };

    fetchTimezones();
  }, []);

  const handleSearch = (query) => {
    const lowercaseQuery = query.toLowerCase();
    const filtered = timezones.filter(
      (timezone) => timezone.name.toLowerCase().includes(lowercaseQuery)
    );
    setFilteredTimezones(filtered);
    setCurrentPage(1); // Reset to the first page when searching
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(filteredTimezones.length / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const visibleTimezones = filteredTimezones.slice(
    startIndex,
    startIndex + pageSize
  );

  return (
    <div>
      <h2>Timezones and Offsets</h2>
      <div>
        <input
        className="calculator-input"
          type="text"
          placeholder="Search Timezones"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyUp={(e) => handleSearch(e.target.value)}
        />
      </div>
      <table border="1">
        <thead>
          <tr>
            <th>Timezone</th>
            <th>Offset from UTC</th>
          </tr>
        </thead>
        <tbody>
          {visibleTimezones.map((timezone, index) => (
            <tr key={index}>
              <td>{timezone.name}</td>
              <td>{timezone.offset}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <span>Page {currentPage} of {totalPages}</span>
        <button
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous
        </button>
        <button
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default TimezoneTable;
