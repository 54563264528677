// Home.js
import React from "react";
import { Link } from "react-router-dom";
import { CategoryLinks } from "../components/CategoryLinks";
import { Content } from "../Content";
import DateTimeCalculatorList from "./date-time/date-time-calculators.json";
import FinancialCalculatorList from "./financial/financial-calculators.json";
import MathCalculatorList from "./math/math-calculators.json";
import MiscCalculatorList from "./misc/misc-calculators.json";

export const HomePage = () => {
  return (
    <Content
      title="CalcuCrafter - Your Ultimate Calculator Toolbox"
      description="Elevate your calculations with our diverse, free tools—from mortgages to science.
      Experience precise results effortlessly, at no cost!">
      <div className="page thinner-page">
        <div className="home-top-section">
          <h1>Welcome to CalcuCrafter.</h1>
          <p>Your one-stop destination for free calculators.</p>
        </div>
        <div className="link-section">
          <h3 className="home-header-links">
            <Link to="/math">Math Calculators</Link>
          </h3>
          <CategoryLinks calcLinks={MathCalculatorList} />
        </div>
        <div className="link-section">
          <h3 className="home-header-links">
            <Link to="/date-time">Date & Time Calculators</Link>
          </h3>
          <CategoryLinks calcLinks={DateTimeCalculatorList} />
        </div>
        <div className="link-section">
          <h3 className="home-header-links">
            <Link to="/financial">Financial Calculators</Link>
          </h3>
          <CategoryLinks calcLinks={FinancialCalculatorList} />
        </div>
        <div className="link-section">
          <h3 className="home-header-links">
            <Link to="/misc">Miscellaneous Calculators</Link>
          </h3>
          <CategoryLinks calcLinks={MiscCalculatorList} />
        </div>
      </div>
    </Content>
  );
};
