// App.js
import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { HomePage } from "./pages/HomePage";
import { MathPage } from "./pages/MathPage";
import { FinancialPage } from "./pages/FinancialPage";
import { DateTimePage } from "./pages/DateTimePage";
import { MiscPage } from "./pages/MiscPage";
import { ContactUsPage } from "./pages/ContactUsPage";
import { AboutUsPage } from "./pages/AboutUsPage"
import { NotFoundPage } from "./pages/NotFoundPage";

import { AgeCalculatorPage } from "./pages/date-time/AgeCalculatorPage";
import { DateCalculatorPage } from "./pages/date-time/DateCalculatorPage";
import { TimeCalculatorPage } from "./pages/date-time/TimeCalculatorPage";
import { TimezoneConverterPage } from "./pages/date-time/TimezoneConverterPage";

import { InterestRateCalculatorPage } from "./pages/financial/InterestRateCalculatorPage";
import { MortgageCalculatorPage } from "./pages/financial/MortgageCalculatorPage";
import { ROICalculatorPage } from "./pages/financial/ROICalculatorPage";

import { ExponentCalculatorPage } from "./pages/math/ExponentCalculatorPage";
import { MeanMedianModeRangeCalculatorPage } from "./pages/math/MeanMedianModeRangePage";
import { PercentageCalculatorPage } from "./pages/math/PercentageCalculatorPage";
import { QuadraticEquationPage } from "./pages/math/QuadraticEquationPage";
import { RootCalculatorPage } from "./pages/math/RootCalculatorPage";
import { HexCalculatorPage } from "./pages/math/HexCalculatorPage";
import { BinaryCalculatorPage } from "./pages/math/BinaryCalculatorPage";
import { LogCalculatorPage } from "./pages/math/LogCalculatorPage";
import { BandwidthCalculatorPage } from "./pages/math/BandwidthCalculatorPage";

import { AreaConverterPage } from "./pages/misc/AreaConverterPage";
import { LengthConverterPage } from "./pages/misc/LengthConverterPage";
import { RandomNumberGeneratorPage } from "./pages/misc/RandomNumberGeneratorPage";
import { SalaryCalculatorPage } from "./pages/misc/SalaryCalculatorPage";
import { TemperatureConverterPage } from "./pages/misc/TemperatureConverterPage";
import { StatisticalSignificanceCalculatorPage } from "./pages/misc/StatisticalSignificanceCalculatorPage";

const App = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path="" element={<HomePage />} exact/>
        <Route path="/math" element={<MathPage />} />
        <Route path="/financial" element={<FinancialPage />} />
        <Route path="/date-time" element={<DateTimePage />} />
        <Route path="/misc" element={<MiscPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/about-us" element={<AboutUsPage />} />

        {/* Date & Time */}
        <Route path="/date-time/age-calculator" element={<AgeCalculatorPage />} />
        <Route path="/date-time/date-calculator" element={<DateCalculatorPage />} />
        <Route path="/date-time/time-calculator" element={<TimeCalculatorPage />} />
        <Route path="/date-time/timezone-calculator" element={<TimezoneConverterPage />} />

        {/* Financial */}
        <Route path="/financial/interest-rate-calculator" element={<InterestRateCalculatorPage />} />
        <Route path="/financial/mortgage-calculator" element={<MortgageCalculatorPage />} />
        <Route path="/financial/roi-calculator" element={<ROICalculatorPage />} />

        {/* Math */}
        <Route path="/math/exponent-calculator" element={<ExponentCalculatorPage />} />
        <Route path="/math/mean-median-mode-range-calculator" element={<MeanMedianModeRangeCalculatorPage />} />
        <Route path="/math/percentage-calculator" element={<PercentageCalculatorPage />} />
        <Route path="/math/quadratic-equation-calculator" element={<QuadraticEquationPage />} />
        <Route path="/math/root-calculator" element={<RootCalculatorPage />} />
        <Route path="/math/hex-calculator" element={<HexCalculatorPage />} />
        <Route path="/math/binary-calculator" element={<BinaryCalculatorPage />} />
        <Route path="/math/log-calculator" element={<LogCalculatorPage />} />
        <Route path="/math/bandwidth-calculator" element={<BandwidthCalculatorPage />} />

        {/* Miscellaneous */}
        <Route path="/misc/area-converter" element={<AreaConverterPage />} />
        <Route path="/misc/length-converter" element={<LengthConverterPage />} />
        <Route path="/misc/random-number-generator" element={<RandomNumberGeneratorPage />} />
        <Route path="/misc/salary-calculator" element={<SalaryCalculatorPage />} />
        <Route path="/misc/statistical-significance-calculator" element={<StatisticalSignificanceCalculatorPage />} />
        <Route path="/misc/temperature-converter" element={<TemperatureConverterPage />} />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
