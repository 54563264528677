import { Content } from "../../Content";
import React, { useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import "../../styles/calculator.css";

export const DateCalculatorPage = () => {
  const [inputDate, setInputDate] = useState("");
  const [yearsToAdd, setYearsToAdd] = useState(0);
  const [monthsToAdd, setMonthsToAdd] = useState(0);
  const [daysToAdd, setDaysToAdd] = useState(0);
  const [isSubtractMode, setIsSubtractMode] = useState(false);
  const [resultDate, setResultDate] = useState(null);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [result, setResult] = useState(null);

  const handleDateChange = (e) => {
    setInputDate(e.target.value);
  };

  const handleYearsChange = (e) => {
    setYearsToAdd(e.target.value);
  };

  const handleMonthsChange = (e) => {
    setMonthsToAdd(e.target.value);
  };

  const handleDaysChange = (e) => {
    setDaysToAdd(e.target.value);
  };

  const toggleMode = () => {
    setIsSubtractMode(!isSubtractMode);
  };

  const calculateDays = () => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const timeDifference = end.getTime() - start.getTime();
    const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

    setResult(daysDifference);
  };

  const calculateDate = () => {
    const inputDateTime = new Date(inputDate);

    if (!isNaN(inputDateTime.getTime())) {
      const resultDateTime = new Date(inputDateTime);
      const yearsModifier = isSubtractMode ? -1 : 1;
      const monthsModifier = isSubtractMode ? -1 : 1;
      const daysModifier = isSubtractMode ? -1 : 1;

      resultDateTime.setFullYear(
        resultDateTime.getFullYear() + parseInt(yearsToAdd, 10) * yearsModifier
      );
      resultDateTime.setMonth(
        resultDateTime.getMonth() + parseInt(monthsToAdd, 10) * monthsModifier
      );
      resultDateTime.setDate(
        resultDateTime.getDate() + parseInt(daysToAdd, 10) * daysModifier
      );

      setResultDate(resultDateTime.toISOString().split("T")[0]);
    } else {
      setResultDate(null);
    }
  };

  return (
    <Content
      title="Date Calculator"
      description="Easily compute dates with precision using our date calculator tool.
      Effortlessly determine durations, future or past dates, and age breakdowns."
    >
      <div className="top-slice-container">
        <Breadcrumb />
        <h1>Date Calculator</h1>
        <div>
          <label>
            Start Date:
            <input
              className="calculator-input"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </label>
          <label>
            End Date:
            <input
              className="calculator-input"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </label>
          <button className="calculate-button" onClick={calculateDays}>
            Calculate Days
          </button>

          {result !== null && (
            <div>
              <p>Number of days between the two dates: {result} days</p>
            </div>
          )}
        </div>

        <div>
          <div>
            <label>Input Date: </label>
            <input
              type="date"
              className="calculator-input"
              value={inputDate}
              onChange={handleDateChange}
            />
          </div>
          <div>
            <label>Years to Add/Subtract: </label>
            <input
              className="calculator-input"
              type="number"
              value={yearsToAdd}
              onChange={handleYearsChange}
            />
          </div>
          <div>
            <label>Months to Add/Subtract: </label>
            <input
              className="calculator-input"
              type="number"
              value={monthsToAdd}
              onChange={handleMonthsChange}
            />
          </div>
          <div>
            <label>Days to Add/Subtract: </label>
            <input
              type="number"
              className="calculator-input"
              value={daysToAdd}
              onChange={handleDaysChange}
            />
          </div>
          <div>
            <label>Operation: </label>
            <button className="calculate-button" onClick={toggleMode}>
              {isSubtractMode ? "Subtract" : "Add"}
            </button>
          </div>
          <button className="calculate-button" onClick={calculateDate}>
            Calculate
          </button>
          {resultDate && (
            <div>
              <h3>Result Date:</h3>
              <p>{resultDate}</p>
            </div>
          )}
        </div>
      </div>

      <div className="info-paragraphs margin-auto">
        <h2>The History of the Gregorian Calendar:</h2>
        <p>
          The Gregorian calendar, the most widely used calendar system in the
          world today, has a rich and complex history spanning centuries. It was
          introduced by Pope Gregory XIII in 1582 as a reform of the Julian
          calendar, which had been in use since the time of Julius Caesar in 45
          BCE. The motivation behind the reform was to correct inaccuracies in
          the Julian calendar that had accumulated over time, particularly in
          the calculation of leap years.
        </p>
        <p>
          One of the main issues addressed by the Gregorian calendar reform was
          the discrepancy between the calendar year and the solar year. The
          Julian calendar had slightly overestimated the length of the solar
          year, resulting in an accumulation of too many leap years. To rectify
          this, the Gregorian calendar adjusted the leap year rule, stipulating
          that years divisible by 100 would only be leap years if they were also
          divisible by 400. This change effectively reduced the number of leap
          years, aligning the calendar more closely with the solar year.
        </p>

        <p>
          The implementation of the Gregorian calendar was not immediate
          worldwide. Initially, it was adopted by Catholic countries in Europe,
          while Protestant and Eastern Orthodox nations continued to use the
          Julian calendar for some time. The adoption of the Gregorian calendar
          varied across different regions and took place over several centuries.
          For example, Great Britain and its colonies did not switch to the
          Gregorian calendar until 1752, resulting in the famous "calendar
          correction" when 11 days were skipped to align with the new system.
        </p>

        <p>
          Despite its widespread adoption, the Gregorian calendar has not been
          without controversy. Some groups, particularly in non-Christian
          cultures, have resisted its imposition due to cultural or religious
          reasons. Additionally, debates continue regarding potential reforms or
          alternative calendar systems that may better accommodate modern
          astronomical knowledge or cultural diversity.
        </p>

        <p>
          Nevertheless, the Gregorian calendar remains the standard for civil
          and religious purposes in most parts of the world, serving as a
          fundamental tool for organizing time and scheduling events. Its
          history reflects the complex interplay of scientific, religious, and
          cultural factors that have shaped human civilization over the
          centuries.
        </p>
        <h2>Holidays</h2>
        <p>
          Holidays are special occasions celebrated by people around the world,
          often with cultural, religious, or historical significance. They
          provide a break from routine, allowing individuals to come together,
          express joy, and honor important events or traditions. One of the most
          universal holidays is New Year's Day, marking the beginning of a new
          year according to the Gregorian calendar. It's a time for reflection,
          resolutions, and jubilant festivities, with fireworks, parties, and
          countdowns occurring in cities worldwide.
        </p>

        <p>
          Religious holidays hold significant importance for many people, with
          various faiths observing their own sacred days. For example, Christmas
          is a Christian holiday commemorating the birth of Jesus Christ,
          celebrated on December 25th with religious services, gift-giving, and
          gatherings with loved ones. Similarly, Eid al-Fitr and Eid al-Adha are
          Islamic holidays marking the end of Ramadan and the conclusion of the
          Hajj pilgrimage respectively, characterized by prayers, feasting, and
          acts of charity.
        </p>

        <p>
          Cultural holidays reflect the unique traditions and heritage of
          different societies. For instance, Diwali, also known as the Festival
          of Lights, is celebrated by Hindus, Sikhs, and Jains, symbolizing the
          victory of light over darkness and good over evil. During Diwali,
          homes are illuminated with lamps, and people exchange gifts, enjoy
          festive meals, and set off fireworks. In Japan, the cherry blossom
          season is celebrated with Hanami, a custom of admiring the transient
          beauty of cherry blossoms, often accompanied by picnics and social
          gatherings under the blooming trees.
        </p>

        <p>
          National holidays commemorate important events or milestones in a
          country's history, serving as opportunities for patriotism and unity.
          Independence Day, such as the Fourth of July in the United States or
          Bastille Day in France, honors the nation's freedom and sovereignty
          with parades, concerts, and displays of national pride. Similarly,
          Republic Day in India marks the adoption of the country's constitution
          and is celebrated with grand ceremonies, flag hoisting, and cultural
          performances.
        </p>

        <p>
          Overall, holidays play a vital role in human culture, fostering a
          sense of belonging, community, and shared identity. They provide
          occasions for reflection, celebration, and connection, enriching the
          human experience with meaning and tradition.
        </p>

        <h2>US Holidays</h2>

        <table border="1">
          <tr>
            <th>Holiday</th>
            <th>Date</th>
            <th>Description</th>
          </tr>
          <tr>
            <td>New Year's Day</td>
            <td>January 1</td>
            <td>Marks the beginning of the Gregorian calendar year.</td>
          </tr>
          <tr>
            <td>Martin Luther King Jr. Day</td>
            <td>Third Monday of January</td>
            <td>
              Honors the civil rights leader's legacy and contributions to
              social justice.
            </td>
          </tr>
          <tr>
            <td>Presidents' Day</td>
            <td>Third Monday of February</td>
            <td>
              Commemorates past U.S. presidents, particularly George Washington
              and Abraham Lincoln.
            </td>
          </tr>
          <tr>
            <td>Memorial Day</td>
            <td>Last Monday of May</td>
            <td>
              Honors the men and women who died while serving in the U.S.
              military.
            </td>
          </tr>
          <tr>
            <td>Independence Day</td>
            <td>July 4</td>
            <td>
              Celebrates the adoption of the Declaration of Independence in
              1776.
            </td>
          </tr>
          <tr>
            <td>Labor Day</td>
            <td>First Monday of September</td>
            <td>
              Celebrates the contributions of American workers and the labor
              movement.
            </td>
          </tr>
          <tr>
            <td>Columbus Day</td>
            <td>Second Monday of October</td>
            <td>
              Commemorates Christopher Columbus's arrival in the Americas.
            </td>
          </tr>
          <tr>
            <td>Veterans Day</td>
            <td>November 11</td>
            <td>
              Honors military veterans who served in the U.S. Armed Forces.
            </td>
          </tr>
          <tr>
            <td>Thanksgiving Day</td>
            <td>Fourth Thursday of November</td>
            <td>
              Traditionally a harvest festival, it's a day of giving thanks and
              feasting.
            </td>
          </tr>
          <tr>
            <td>Christmas Day</td>
            <td>December 25</td>
            <td>Celebrates the birth of Jesus Christ.</td>
          </tr>
        </table>
      </div>
    </Content>
  );
};
