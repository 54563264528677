import { Content } from "../Content";
import { CategoryLinks } from "../components/CategoryLinks";
import MiscCalculatorList from "./misc/misc-calculators.json";

export const MiscPage = () => {
  return (
    <Content
      title="Miscellaneous Calculators"
      description="Explore a diverse array of calculators for various needs. Simplify your tasks with our comprehensive tool collection.">
    <div className="link-page-padding">
      <h1>Miscellaneous Calculators</h1>
      <CategoryLinks calcLinks={MiscCalculatorList} />
    </div>
    </Content>
  );
};
