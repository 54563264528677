import React, { useState } from "react";
import { Content } from "../../Content";
import Breadcrumb from "../../components/Breadcrumb";
import "../../styles/calculator.css";

export const RandomNumberGeneratorPage = () => {
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [randomNumber, setRandomNumber] = useState(null);

  const handleMinValueChange = (e) => {
    setMinValue(e.target.value);
    setRandomNumber(null); // Clear the random number when min value is changed
  };

  const handleMaxValueChange = (e) => {
    setMaxValue(e.target.value);
    setRandomNumber(null); // Clear the random number when max value is changed
  };

  const generateRandomNumber = () => {
    const min = parseFloat(minValue);
    const max = parseFloat(maxValue);

    if (!isNaN(min) && !isNaN(max) && min <= max) {
      const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
      setRandomNumber(randomNum);
    }
  };

  return (
    <Content
      title="Random Number Generator"
      description="Explore randomness with our handy generator.
      Generate random numbers for games, simulations, and more with ease."
    >
      <div className="top-slice-container">
        <Breadcrumb />
        <h1>Random Number Generator</h1>
        <div>
          <label>Min Value: </label>
          <input
            type="number"
            className="calculator-input"
            value={minValue}
            onChange={handleMinValueChange}
          />
        </div>
        <div>
          <label>Max Value: </label>
          <input
            type="number"
            className="calculator-input"
            value={maxValue}
            onChange={handleMaxValueChange}
          />
        </div>
        <button className="calculate-button" onClick={generateRandomNumber}>
          Generate Random Number
        </button>
        {randomNumber !== null && (
          <div>
            <h3>Random Number:</h3>
            <p>{randomNumber}</p>
          </div>
        )}
      </div>

      <div className="info-paragraphs margin-auto">
        <h2>The Historical Evolution of Random Number Generation:</h2>
        <p>
          The history of random number generation is intertwined with the
          evolution of mathematics, probability theory, and the quest for
          unpredictability. Ancient cultures, such as the Chinese and Greeks,
          explored the concepts of chance and randomness in games of chance. The
          17th-century mathematician Blaise Pascal and Pierre de Fermat laid the
          foundation for probability theory, introducing the concept of expected
          values. However, true random number generation faced challenges until
          the advent of modern computing in the mid-20th century. The electronic
          generation of random numbers, driven by hardware or algorithms,
          revolutionized fields such as cryptography, simulations, and
          statistical analysis.
        </p>

        <h2>Nuances in Random Number Generation:</h2>
        <p>
          Generating truly random numbers presents challenges due to the
          deterministic nature of computers. Pseudo-random number generators
          (PRNGs) are widely used, relying on algorithms to produce sequences
          that mimic randomness. However, the periodic nature of PRNGs and their
          sensitivity to initial conditions introduce nuances. Cryptographically
          secure generators address vulnerabilities in applications requiring
          high-security standards. Understanding the statistical properties of
          generated sequences, such as uniform distribution and independence, is
          crucial for reliable and unbiased random number generation.
        </p>

        <h2>Applications in Gaming and Simulations:</h2>
        <p>
          Random number generation plays a vital role in gaming and simulations,
          enhancing user experiences and providing realistic scenarios. In video
          games, random numbers determine outcomes such as character movements,
          item drops, and procedural level generation. Simulations in various
          fields, from physics and finance to climate modeling, rely on random
          numbers to introduce variability and simulate complex systems.
          Ensuring the quality and unpredictability of generated numbers is
          essential for the accuracy and credibility of these applications.
        </p>

        <h2>Challenges in Cryptography and Security:</h2>
        <p>
          Cryptographic applications demand a higher level of randomness to
          ensure security. Cryptographically secure random number generators
          (CSPRNGs) are designed to withstand attacks and resist predictability.
          Flaws in random number generation can compromise the integrity of
          encryption keys, leading to vulnerabilities in secure communication
          and data protection. Ongoing research focuses on developing robust
          methods for generating random numbers with properties suitable for
          cryptographic applications.
        </p>

        <h2>Hardware-Based Randomness:</h2>
        <p>
          Hardware-based random number generators leverage physical processes,
          such as electronic noise or radioactive decay, to achieve true
          randomness. These methods are less susceptible to algorithmic
          predictability and are often used in applications requiring a higher
          level of entropy. Hardware random number generators find applications
          in cryptographic devices, secure key generation, and other scenarios
          where true randomness is critical for maintaining security and
          unpredictability.
        </p>

        <h2>Emerging Trends and Quantum Randomness:</h2>
        <p>
          Emerging technologies, such as quantum computers, offer new
          possibilities for random number generation. Quantum randomness, based
          on the intrinsic uncertainty of quantum mechanics, provides a
          theoretically unbreakable source of randomness. Quantum random number
          generators (QRNGs) are being explored for applications requiring the
          highest level of security. As quantum computing continues to advance,
          the integration of quantum randomness is expected to influence the
          landscape of random number generation in the digital age.
        </p>
      </div>
    </Content>
  );
};
