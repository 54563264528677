// Header.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../App.css";
import DateTimeCalculatorList from "../pages/date-time/date-time-calculators.json";
import FinancialCalculatorList from "../pages/financial/financial-calculators.json";
import MathCalculatorList from "../pages/math/math-calculators.json";
import MiscCalculatorList from "../pages/misc/misc-calculators.json";

export const Header = () => {
  const allCalculatorsList = [
    ...DateTimeCalculatorList.calculators,
    ...FinancialCalculatorList.calculators,
    ...MathCalculatorList.calculators,
    ...MiscCalculatorList.calculators
  ]

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isSearchMenuOpen, setSearchMenuOpen] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const filteredCalculators = allCalculatorsList.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggleMobileMenu = () => {
    setSearchMenuOpen(false);
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleSearchMenu = () => {
    setMobileMenuOpen(false);
    setSearchMenuOpen(!isSearchMenuOpen);
  };

  return (
    <header className={`header ${isMobileMenuOpen ? "mobile-menu-open" : ""}`}>
      <div className="header-wrapper">
        <span className="logo">
          <Link to="">
            CalcuCrafter
          </Link>
        </span>

        <div className="hamburger-icon" onClick={toggleMobileMenu}>
          &#9776;
        </div>

        <span className={`header-nav-container ${isMobileMenuOpen ? "show-mobile-menu" : ""}`}>
          <nav className="header-nav">
            <Link to="/math" className="header-link" onClick={toggleMobileMenu}>
            Mathematical
            </Link>
            <Link to="/financial" className="header-link" onClick={toggleMobileMenu}>
            Financial
            </Link>
            <Link to="/date-time" className="header-link" onClick={toggleMobileMenu}>
            Time & Date
            </Link>
            <Link to="/misc" className="header-link" onClick={toggleMobileMenu}>
            Misc
            </Link>
            <Link to="/contact-us" className="header-link" onClick={toggleMobileMenu}>
            Contact Us
            </Link>
          </nav>
        </span>
        <button className="search-button" onClick={toggleSearchMenu}>Search</button>
        <span className={`search-container ${isSearchMenuOpen ? "show-search-menu" : ""}`}>
          <input
            type="text"
            placeholder="Search..."
            className="header-search-input"
            onChange={(e) => setSearchTerm(e.target.value)}
             />
          <ul className="header-calculator-search">
            {filteredCalculators.map((item) => (
              <li key={item.id} className="header-calculator-list">
                <Link to={item.path} onClick={toggleSearchMenu}>
                {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </span>
      </div>
    </header>
  );
};
