import React, { useEffect } from "react";

export const Content = ({ children, title, description }) => {
  useEffect(() => {
    if (title !== "" && title != null) {
      document.title = title;
    }

    if (description !== "" && description != null) {
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", description);
    }

    if (description !== "" && description != null && title !== "" && title != null) {
        document
        .querySelector('script[type="application/ld+json"]')
        .textContent = `{"@context": "https://schema.org","@type":"calculator","headline":"${title}","description":"${description}"}`
    }
  }, [title, description]);

  return (
    <div id="layout">
      <div id="content">{children}</div>
    </div>
  );
};
