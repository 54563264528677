import React, { useState } from "react";
import { Content } from "../../Content";
import Breadcrumb from "../../components/Breadcrumb";
import "../../styles/calculator.css";

export const RootCalculatorPage = () => {
  const [number, setNumber] = useState(0);
  const [rootType, setRootType] = useState("square");
  const [result, setResult] = useState(null);
  const [showNthInput, setShowNthInput] = useState(false);
  const [nthInput, setNthInput] = useState(false);

  const setRootSelectType = (value) => {
    const selectedValue = value;
    if (selectedValue === "nth") {
      setShowNthInput(true);
    }

    setRootType(selectedValue);
  };

  const calculateRoot = () => {
    if (rootType === "square") {
      setResult(Math.sqrt(number));
    } else if (rootType === "cube") {
      setResult(Math.cbrt(number));
    } else if (rootType === "nth") {
      if (nthInput) {
        setResult(Math.pow(number, 1 / nthInput));
      }
    }
  };

  return (
    <Content
      title="Root Calculator"
      description="Unlock the complexity of mathematical roots with our user-friendly calculator.
      Simplify your calculations and solve equations with ease."
    >
      <div className="top-slice-container">
        <Breadcrumb />
        <h1>Root Calculator</h1>
        <div>
          <label>
            Enter a number:
            <input
              className="calculator-input"
              type="number"
              value={number}
              onChange={(e) => setNumber(Number(e.target.value))}
            />
          </label>
        </div>
        <div>
          <label>
            Select root type:
            <select
              className="calculator-select"
              value={rootType}
              onChange={(e) => setRootSelectType(e.target.value)}
            >
              <option value="square">Square Root</option>
              <option value="cube">Cube Root</option>
              <option value="nth">nth Root (Custom)</option>
            </select>
            {showNthInput && (
              <div>
                <label>Nth Input:</label>
                <input
                  className="calculator-input"
                  type="text"
                  placeholder="Nth root..."
                  onChange={(e) => setNthInput(Number(e.target.value))}
                />
              </div>
            )}
          </label>
        </div>
        <button className="calculate-button" onClick={calculateRoot}>
          Calculate Root
        </button>
        <div>
          {result !== null ? (
            <div>
              <h3>Result:</h3>
              <p>{`The ${
                rootType === "2" ? "nth" : rootType
              }-root is: ${result}`}</p>
            </div>
          ) : null}
        </div>
      </div>

      <div className="info-paragraphs margin-auto">
        <h2>The Historical Evolution of Root Calculations:</h2>
        <p>
          Root calculations have a deep historical connection with the evolution
          of mathematical knowledge across ancient civilizations. Early
          mathematicians, including the Babylonians and Egyptians, developed
          methods for approximating square roots, often relying on geometric
          constructions. The ancient Greeks contributed to the understanding of
          square and cube roots, with mathematicians like Euclid exploring the
          relationships between numbers and geometric shapes. Over centuries,
          scholars in India, the Islamic world, and medieval Europe further
          refined techniques for extracting roots, laying the groundwork for the
          algebraic methods we use today.
        </p>

        <h2>Nuances in Root Calculations:</h2>
        <p>
          While root calculations seem straightforward, several nuances impact
          their computation and interpretation. One fundamental nuance involves
          distinguishing between different types of roots, such as square roots,
          cube roots, and nth roots. The concept of principal roots, which
          involves selecting a specific root from multiple possibilities, adds
          complexity. Additionally, considering the domain and range of the
          function is crucial, as roots may be defined only within certain
          bounds or may involve complex numbers in certain contexts.
        </p>

        <h2>Radical Notation and Surds:</h2>
        <p>
          Radical notation, expressed as √x for square roots or ∛x for cube
          roots, is commonly used to represent roots. While this notation
          simplifies the expression of roots, it doesn't always provide a
          precise numerical value. Surds, or expressions involving square roots
          of non-perfect squares, introduce an additional layer of complexity.
          Handling surds requires techniques such as rationalization to express
          roots in simplified forms and facilitate mathematical operations.
        </p>

        <h2>Real and Complex Roots:</h2>
        <p>
          Root calculations may result in either real or complex roots,
          depending on the nature of the expression. Quadratic equations, for
          instance, may yield real roots, repeated roots, or complex conjugate
          roots. Understanding the distinction between these scenarios is
          crucial for interpreting solutions and applying them in real-world
          contexts. Complex roots often arise in fields like electrical
          engineering and quantum mechanics, extending the applicability of root
          calculations beyond the realm of real numbers.
        </p>

        <h2>Numerical Methods and Approximations:</h2>
        <p>
          Root calculations for non-algebraic functions, transcendental
          equations, or equations with no closed-form solutions often require
          numerical methods. Iterative techniques like the Newton-Raphson method
          or bisection method enable approximating roots with a high degree of
          precision. However, it's essential to consider convergence criteria
          and the potential for multiple roots or convergence to local minima or
          maxima in the numerical solution process.
        </p>

        <h2>Modern Tools and Computational Advances:</h2>
        <p>
          Advancements in computing have transformed root calculations, allowing
          for the efficient computation of roots for complex and large-scale
          mathematical problems. Numerical software, symbolic computing tools,
          and programming languages equipped with root-finding algorithms
          streamline the process. The ability to handle intricate root
          calculations has facilitated breakthroughs in various scientific
          disciplines, making root-finding a vital component of contemporary
          mathematical exploration and problem-solving.
        </p>
      </div>
    </Content>
  );
};
