import React, { useState } from "react";
import { Content } from "../../Content";
import Breadcrumb from "../../components/Breadcrumb";
import "../../styles/calculator.css";

export const BinaryCalculatorPage = () => {
  const [input1, setInput1] = useState("10101010");
  const [input2, setInput2] = useState("11001100");
  const [operation, setOperation] = useState("add");
  const [calcResult, setCalculationResult] = useState("");

  const [inputBToD, setinputBToD] = useState("10101010");
  const [bToDResult, calcBToDResult] = useState("");

  const [inputDToB, setinputDToB] = useState("170");
  const [dToBResult, calcDToBResult] = useState("");

  const handleInputChange1 = (e) => {
    setInput1(e.target.value);
  };

  const handleInputChange2 = (e) => {
    setInput2(e.target.value);
  };

  const handleOperationChange = (e) => {
    setOperation(e.target.value);
  };

  const handleBToDInputChange = (e) => {
    setinputBToD(e.target.value);
  };

  const handleDToBInputChange = (e) => {
    setinputDToB(e.target.value);
  };

  const calculate = () => {
    let resultValue;
    const n1 = parseInt(input1, 2);
    const n2 = parseInt(input2, 2);

    switch (operation) {
      case "add":
        resultValue = decimalToBinary(n1 + n2).toString(2);
        break;
      case "subtract":
        resultValue = decimalToBinary(n1 - n2).toString(2);
        break;
      case "multiply":
        resultValue = decimalToBinary(n1 * n2).toString(2);
        break;
      case "divide":
        if (n2 !== 0) {
          resultValue = decimalToBinary(n1 / n2).toString(2);
        } else {
          resultValue = "Can't divide by zero";
        }
        break;
      default:
        resultValue = "Invalid operation";
    }

    setCalculationResult(resultValue);
  };

  const calculateBToD = () => {
    const decimalResult = binaryToDecimal(inputBToD);
    calcBToDResult(decimalResult);
  };

  function decimalToBinary(decimal) {
    if (decimal === 0) return "0";

    let binary = "";
    while (decimal > 0) {
      binary = (decimal % 2) + binary;
      decimal = Math.floor(decimal / 2);
    }
    return binary;
  }

  const calculateDToB = () => {
    const binaryResult = decimalToBinary(parseInt(inputDToB));
    calcDToBResult(binaryResult);
  };

  function binaryToDecimal(binary) {
    let decimal = 0;
    for (let i = binary.length - 1; i >= 0; i--) {
      const digit = parseInt(binary[i]);
      decimal += digit * Math.pow(2, binary.length - 1 - i);
    }
    return decimal;
  }

  return (
    <Content
      title="Binary Calculator"
      description="Explore binary calculations with our efficient calculator.
      Perform operations seamlessly. Dive into binary arithmetic now."
    >
      <div className="top-slice-container">
        <Breadcrumb />
        <h1>Binary Calculator</h1>
        <div>
          <h2>Binary Calculation—Add, Subtract, Multiply, or Divide</h2>
          <input
            type="text"
            className="calculator-input"
            value={input1}
            onChange={handleInputChange1}
          />
          <select
            value={operation}
            className="calculator-select"
            onChange={handleOperationChange}
          >
            <option value="add">+</option>
            <option value="subtract">-</option>
            <option value="multiply">*</option>
            <option value="divide">÷</option>
          </select>
          <input
            type="text"
            className="calculator-input"
            value={input2}
            onChange={handleInputChange2}
          />
          {calcResult ? (
            <div className="binary-results-text">Result: {calcResult}</div>
          ) : null}
          <button className="calculate-button" onClick={calculate}>
            Calculate
          </button>
        </div>

        <div>
          <h2>Convert Binary to Decimal Value</h2>
          <input
            type="text"
            className="calculator-input"
            value={inputBToD}
            onChange={handleBToDInputChange}
          />
          {bToDResult ? (
            <div className="binary-results-text">Result: {bToDResult}</div>
          ) : null}
          <button className="calculate-button" onClick={calculateBToD}>
            Calculate
          </button>
        </div>

        <div>
          <h2>Convert Decimal Value to Binary</h2>
          <input
            type="text"
            className="calculator-input"
            value={inputDToB}
            onChange={handleDToBInputChange}
            placeholder="170"
          />
          {dToBResult ? (
            <div className="binary-results-text">Result: {dToBResult}</div>
          ) : null}
          <button className="calculate-button" onClick={calculateDToB}>
            Calculate
          </button>
        </div>
      </div>

      <div className="info-paragraphs margin-auto">
        <h2>Binary Number System</h2>
        <p>
          The binary number system serves as the fundamental language of digital
          technology, underpinning the operation of modern computers and
          electronic devices. Unlike the decimal system, which utilizes ten
          digits (0 through 9), the binary system relies solely on two digits: 0
          and 1. Each digit in a binary number represents a power of 2, with the
          rightmost digit representing 2^0, the next digit to the left
          representing 2^1, and so forth. This simplicity forms the backbone of
          binary arithmetic, allowing for efficient representation and
          manipulation of data in electronic circuits and computer memory.
        </p>

        <p>
          In the binary system, each position holds a value that is a power of
          2, with the value doubling as you move from right to left. For
          example, the binary number 1010 represents (1 * 2^3) + (0 * 2^2) + (1
          * 2^1) + (0 * 2^0), which equals 10 in the decimal system. This
          positional notation facilitates compact representation of numbers,
          with each digit carrying significant weight in determining the overall
          value of the number. This property makes binary ideal for digital
          electronics, where data is processed and stored as sequences of 0s and
          1s.
        </p>

        <p>
          One of the key advantages of the binary system is its compatibility
          with digital circuits, where signals are either on (represented by 1)
          or off (represented by 0). This binary representation simplifies the
          design and operation of electronic devices, enabling efficient
          storage, transmission, and manipulation of data. Additionally, binary
          arithmetic lends itself naturally to Boolean logic, allowing for the
          implementation of logical operations such as AND, OR, and NOT gates,
          which form the basis of digital computation.
        </p>
        <p>
          While the binary number system may seem unfamiliar compared to the
          decimal system used in everyday life, its simplicity and efficiency
          have made it indispensable in the realm of computing. From the
          smallest microprocessors to the most powerful supercomputers, binary
          arithmetic powers the digital revolution, shaping the way we
          communicate, work, and interact with technology. Understanding the
          principles of the binary system provides insight into the inner
          workings of modern computing systems, empowering individuals to
          harness the full potential of digital technology in the digital age.
        </p>

        <h2>Binary Code</h2>
        <p>
          Binary code is the fundamental language of computers, representing
          data and instructions using only two symbols: 0 and 1. This system of
          encoding information in binary form forms the backbone of digital
          communication, enabling computers to process, store, and transmit data
          in a language that electronic circuits can understand. In binary code,
          each digit, or bit, represents a single unit of information, with 0
          typically denoting a low voltage or absence of a signal, and 1
          representing a high voltage or presence of a signal.
        </p>

        <p>
          The most common application of binary code is in representing
          characters, numbers, and symbols in digital form. In text-based
          communication, each character in a message is encoded using a unique
          sequence of bits, allowing computers to interpret and display text on
          screens, printers, and other output devices. For example, the ASCII
          (American Standard Code for Information Interchange) encoding scheme
          assigns a unique 7-bit binary code to each alphanumeric character,
          punctuation mark, and control character, facilitating the exchange of
          textual data between computers and electronic devices.
        </p>

        <p>
          Beyond text, binary code is also used to represent other types of
          information, including images, sound, and video. Digital images, for
          instance, are composed of pixels, each of which is represented by a
          binary value indicating its color or intensity. Similarly, digital
          audio and video files store information about sound waves and visual
          frames using binary code, allowing for the creation, editing, and
          playback of multimedia content on computers and digital media players.
        </p>

        <p>
          In addition to its applications in data representation, binary code
          plays a crucial role in the execution of computer programs. Machine
          instructions, which direct the operation of a computer's central
          processing unit (CPU), are encoded in binary form, allowing the CPU to
          interpret and execute them sequentially. These instructions encompass
          a wide range of operations, including arithmetic and logical
          calculations, data movement, control flow, and input/output
          operations, enabling computers to perform complex tasks and operations
          with remarkable speed and precision.
        </p>

        <p>
          In summary, binary code serves as the universal language of digital
          communication and computation, enabling computers to process, store,
          and transmit information in a compact and efficient manner. From text
          and images to software programs and multimedia content, binary code
          forms the foundation of modern technology, shaping the way we interact
          with computers, devices, and digital systems in the digital age.
        </p>

        <h2>Binary Logic</h2>
        <p>
          Binary logic, also known as Boolean logic, is the foundation of
          digital electronics and computer science. It operates on the principle
          that all information can be represented and manipulated using only two
          distinct values: true and false, or equivalently, 1 and 0. This
          simplistic yet powerful system of logic was introduced by George Boole
          in the 19th century and has since become the cornerstone of modern
          computing, enabling the design and implementation of complex systems
          and algorithms.
        </p>

        <p>
          At the heart of binary logic are logical operators, which perform
          operations on one or more binary values to produce a single binary
          result. The primary logical operators include AND, OR, and NOT, each
          of which follows specific rules and truth tables governing their
          behavior. The AND operator returns true only if both of its input
          values are true, the OR operator returns true if at least one of its
          input values is true, and the NOT operator returns the opposite of its
          input value.
        </p>

        <p>
          Binary logic finds widespread applications in digital circuit design,
          where it forms the basis of logic gates, the building blocks of
          digital circuits. These gates, such as AND gates, OR gates, and NOT
          gates, are electronic devices that perform logical operations on
          binary signals, controlling the flow of electricity through a circuit
          based on the input values. By combining these basic logic gates in
          various configurations, engineers can create complex circuits capable
          of performing arithmetic operations, storing data, and executing
          program instructions.
        </p>

        <p>
          In addition to its role in digital circuitry, binary logic is integral
          to computer programming and algorithm design. Boolean expressions,
          which are composed of logical operators and operands, are used to
          represent conditions and control the flow of execution in computer
          programs. Conditional statements, such as if-else statements and
          loops, rely on binary logic to determine which actions to take based
          on the evaluation of Boolean expressions. This enables programmers to
          write efficient and robust software that can make decisions, process
          data, and respond to user input in a logical manner.
        </p>

        <h2>Binary Applications</h2>
        <p>
          Binary applications encompass a broad array of technologies and
          industries where the principles of binary representation and
          computation are employed to solve diverse problems and achieve various
          objectives. One prominent area of binary applications is digital
          computing, where binary code serves as the language of computers,
          enabling them to process, store, and transmit data in the form of
          binary digits, or bits. From personal computers and smartphones to
          supercomputers and embedded systems, binary applications power
          virtually every aspect of modern computing infrastructure, supporting
          tasks ranging from simple arithmetic calculations to complex data
          analysis and machine learning algorithms.
        </p>

        <p>
          In the field of telecommunications, binary applications play a crucial
          role in encoding and transmitting digital signals across communication
          networks. Binary modulation techniques, such as amplitude-shift keying
          (ASK), frequency-shift keying (FSK), and phase-shift keying (PSK), are
          used to convert digital data into analog waveforms that can be
          transmitted over wired or wireless channels. These techniques enable
          high-speed data transmission, reliable communication, and efficient
          bandwidth utilization, making them essential for telecommunications
          systems, including cellular networks, satellite communications, and
          internet protocols.
        </p>

        <p>
          Binary applications also extend to the realm of cryptography and
          cybersecurity, where binary algorithms and protocols are used to
          secure sensitive information and protect digital assets from
          unauthorized access and malicious attacks. Cryptographic techniques,
          such as encryption, digital signatures, and hash functions, rely on
          binary operations to encode data in a secure and tamper-resistant
          format, ensuring confidentiality, integrity, and authenticity of
          information in transit and at rest. These techniques are employed in
          various applications, including secure communication protocols,
          e-commerce transactions, and data storage systems, safeguarding
          sensitive data from cyber threats and privacy breaches.
        </p>

        <p>
          In addition to these fields, binary applications find widespread use
          in digital multimedia, gaming, finance, healthcare, and many other
          sectors, where they enable the creation, processing, and distribution
          of digital content, services, and products. Digital imaging and video
          processing technologies leverage binary representations of pixels and
          frames to capture, manipulate, and display visual information with
          high fidelity and resolution. Financial trading systems and analytics
          platforms use binary algorithms and data structures to analyze market
          trends, forecast future outcomes, and make real-time investment
          decisions. Healthcare informatics systems employ binary code to manage
          electronic health records, analyze medical imaging data, and
          facilitate patient care delivery, improving diagnosis, treatment, and
          outcomes.
        </p>

        <h2>History of Binary</h2>
        <p>
          The history of binary traces back thousands of years, with early
          civilizations employing binary-like systems for various purposes. One
          of the earliest documented instances of binary-like numbering systems
          is the ancient Chinese I Ching, or Book of Changes, dating back to
          around 1000 BCE. The I Ching uses a system of broken (yin) and solid
          (yang) lines to represent the concept of duality and change, serving
          as a philosophical and divinatory tool rather than a numerical system.
        </p>

        <p>
          In the Western world, binary concepts began to emerge in the work of
          philosophers and mathematicians in ancient Greece. The Greek
          philosopher Leucippus (5th century BCE) and his student Democritus
          proposed the idea of atomism, positing that the universe is composed
          of indivisible particles called atoms. While not explicitly binary,
          this concept laid the groundwork for later developments in binary
          representation and logic, as it suggested a fundamental duality
          between existence and non-existence.
        </p>

        <p>
          The true foundation of binary as a numerical system was established in
          the 17th century by the German mathematician and philosopher Gottfried
          Wilhelm Leibniz. Inspired by the Chinese I Ching, Leibniz developed a
          binary system of arithmetic in 1679, which he described in his paper
          "Explication de l'Arithmétique Binaire" (Explanation of Binary
          Arithmetic). In Leibniz's binary system, numbers are represented using
          only two digits, 0 and 1, with positional notation similar to the
          decimal system.
        </p>

        <p>
          While Leibniz's binary system was groundbreaking, it initially
          received little attention outside academic circles. It wasn't until
          the 20th century, with the advent of digital computing, that binary
          became widely recognized as the foundation of modern technology. In
          1937, the American mathematician Claude Shannon introduced the concept
          of Boolean algebra, which formalized binary logic and laid the
          theoretical groundwork for digital circuit design and computer
          programming.
        </p>

        <p>
          During World War II, binary encoding systems played a critical role in
          cryptography and code-breaking efforts. The British cryptanalyst Alan
          Turing developed the Bombe, a machine used to decipher German Enigma
          machine messages, which relied on binary code to represent and
          manipulate encrypted text. Turing's work laid the foundation for
          modern computing and solidified binary as the language of digital
          communication and computation.
        </p>

        <p>
          In the decades that followed, binary applications proliferated with
          the development of electronic computers, telecommunications systems,
          and digital electronics. Today, binary is ubiquitous in computing,
          serving as the basis for digital data representation, communication
          protocols, and algorithmic operations. From microprocessors and memory
          chips to internet protocols and software applications, binary
          continues to shape the technological landscape and drive innovation in
          the digital age.
        </p>
      </div>
    </Content>
  );
};
