import React, { useState } from "react";
import { Content } from "../../Content";
import TimezoneTable from "../../components/TimezoneTable";
import Breadcrumb from "../../components/Breadcrumb";
import "../../styles/calculator.css";

export const TimezoneConverterPage = () => {
  const [inputTime, setInputTime] = useState("");
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [resultTime, setResultTime] = useState(null);

  const handleTimeChange = (e) => {
    setInputTime(e.target.value);
  };

  const handleTimeZoneChange = (e) => {
    setSelectedTimeZone(e.target.value);
  };

  const convertTimezone = () => {
    const inputTimeObject = new Date(`1970-01-01T${inputTime}`);

    if (!isNaN(inputTimeObject.getTime()) && selectedTimeZone) {
      const inputTimeZoneOffset = inputTimeObject.getTimezoneOffset();
      const targetTimeZoneOffset = parseInt(selectedTimeZone, 10) * 60;

      const convertedTimeObject = new Date(
        inputTimeObject.getTime() +
          (targetTimeZoneOffset - inputTimeZoneOffset) * 60000
      );

      const resultHours = convertedTimeObject
        .getHours()
        .toString()
        .padStart(2, "0");
      const resultMinutes = convertedTimeObject
        .getMinutes()
        .toString()
        .padStart(2, "0");
      const resultSeconds = convertedTimeObject
        .getSeconds()
        .toString()
        .padStart(2, "0");

      setResultTime(`${resultHours}:${resultMinutes}:${resultSeconds}`);
    } else {
      setResultTime(null);
    }
  };

  return (
    <Content
      title="Timezone Converter"
      description="This calculator converts between different time zones around the world using
      the GMT format and provides a map delineating standard time zones globally."
    >
      <div className="top-slice-container">
        <Breadcrumb />
        <h1>Timezone Converter</h1>
        <div>
          <label>Input Time: </label>
          <input
            type="time"
            className="calculator-input"
            value={inputTime}
            onChange={handleTimeChange}
          />
        </div>
        <div>
          <label>Target Timezone Offset (in hours): </label>
          <input
            className="calculator-input"
            type="number"
            value={selectedTimeZone}
            onChange={handleTimeZoneChange}
          />
        </div>
        <button className="calculate-button" onClick={convertTimezone}>
          Convert
        </button>
        {resultTime && (
          <div>
            <h3>Converted Time:</h3>
            <p>{resultTime}</p>
          </div>
        )}
      </div>
      <div className="info-paragraphs margin-auto">
        <TimezoneTable />

        <h2>The Evolution of Time Zones</h2>
        <p>
          The establishment of time zones is a testament to humanity's need for
          standardized timekeeping. Before the widespread adoption of time zones
          in the late 19th century, local mean time varied widely between
          cities. The advent of railroads and telegraphs highlighted the
          necessity for a unified system, leading to the introduction of time
          zones at the International Meridian Conference in 1884. The Greenwich
          Mean Time (GMT) was chosen as the prime meridian, laying the
          foundation for our modern time zone system.
        </p>

        <h2>Timezone Conversion</h2>
        <p>
          Timezone conversion, while essential in our globally connected world,
          introduces complexities that demand precision. The intricacies arise
          from factors such as daylight saving time (DST), which is not
          universally observed. During DST transitions, certain regions adjust
          their clocks forward or backward, impacting the time difference
          calculation. To complicate matters, some areas may not adhere to DST
          at all, necessitating constant vigilance in accurate timezone
          conversion calculations.
        </p>

        <h2>Daylight Saving Time Dilemmas</h2>
        <p>
          The practice of Daylight Saving Time adds an additional layer of
          challenge to timezone calculations. Not all countries or regions
          observe DST, and those that do may do so on different dates. As a
          result, a timezone conversion that spans the DST transition period
          requires careful consideration of whether the respective locations are
          currently observing DST or not. This temporal dance underscores the
          dynamic nature of timezones and the need for up-to-date information in
          accurate conversions.
        </p>

        <h2>Technological Advances in Timezone Management:</h2>
        <p>
          Advancements in technology have facilitated more efficient timezone
          management. Modern programming languages and libraries provide tools
          for developers to handle timezone conversions programmatically.
          However, it remains crucial to account for dynamic changes, such as
          updates to timezone rules, to ensure precise calculations. The
          integration of Coordinated Universal Time (UTC) as a standard
          reference further aids in minimizing discrepancies in global
          communications.
        </p>

        <h2>The Future of Timezone Coordination:</h2>
        <p>
          As our world continues to shrink through global connectivity, the
          future of timezone coordination involves ongoing efforts to streamline
          and simplify the process. Standardization of rules for daylight saving
          time and enhanced collaboration between nations to harmonize
          timekeeping practices could contribute to a more seamless experience
          for individuals, businesses, and systems navigating the intricacies of
          timezones.
        </p>
      </div>
    </Content>
  );
};
