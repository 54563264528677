import { Content } from "../Content";
import { CategoryLinks } from "../components/CategoryLinks";
import MathCalculatorList from "./math/math-calculators.json";

export const MathPage = () => {
  return (
    <Content
      title="Math Calculators"
      description="Access a diverse range of math calculators for various equations.
      Simplify your mathematical tasks with our comprehensive tool collection.">
      <div className="link-page-padding">
        <h1>Math Calculators</h1>
        <CategoryLinks calcLinks={MathCalculatorList} />
      </div>
    </Content>
  );
};
