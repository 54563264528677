import React, { useState } from "react";
import { Content } from "../../Content";
import Breadcrumb from "../../components/Breadcrumb";
import "../../styles/calculator.css";

export const BandwidthCalculatorPage = () => {
  const [dataConverterInput, setDataConverterInput] = useState(500);
  const [dataConverterSelect, setDataConverterSelect] = useState("mb");
  const [dataConverterResults, setDataConverterResults] = useState({});

  const [downloadFileSizeInput, setDownloadFileSizeInput] = useState(500);
  const [downloadBandwidthInput, setDownloadBandwidthInput] = useState(5);
  const [downloadFileSizeDropdown, setDownloadFileSizeDropdown] =
    useState("MB");
  const [downloadBandwidthDropdown, setDownloadBandwidthDropdown] =
    useState("mb/s");
  const [downloadTimeResults, setDownloadTimeResults] = useState();

  const [websitePageViewInput, setWebsitePageView] = useState(5000);
  const [pageSizeInput, setPageSizeInput] = useState(500);
  const [redundancyInput, setRedundancy] = useState(2);
  const [bandwidthPageViewDropdown, setBandwidthPageViewDropdown] =
    useState("day");
  const [pageSizeDropdown, setPageSizeDropdown] = useState("kb");
  const [websiteBandwidthResults, setWebsiteBandwidthResults] = useState("");

  const [bandwidthWithoutRedundancy, setBandwidthWithoutRedundancy] =
    useState(null);
  const [bandwidthGBWithoutRedundancy, setBandwidthGBWithoutRedundancy] =
    useState(null);
  const [bandwidthWithRedundancy, setBandwidthWithRedundancy] = useState(null);
  const [bandwidthGBWithRedundancy, setBandwidthGBWithRedundancy] =
    useState(null);

  const calculateDataUnit = () => {
    const bytes = convertToBytes(dataConverterInput);
    if (!isNaN(bytes)) {
      setDataConverterResults({
        b: "Bits: " + formatInt(bytes * 8),
        kb: "Kilobits: " + formatInt((bytes / 1024) * 8),
        mb: "Megabits: " + formatInt((bytes / (1024 * 1024)) * 8),
        gb: "Gigabits: " + formatInt((bytes / (1024 * 1024 * 1024)) * 8),
        tb: "Terabits: " + formatInt((bytes / (1024 * 1024 * 1024 * 1024)) * 8),
        B: "Bytes: " + formatInt(bytes),
        KB: "Kilobytes: " + formatInt(bytes / 1024),
        MB: "Megabytes: " + formatInt(bytes / (1024 * 1024)),
        GB: "Gigabytes: " + formatInt(bytes / (1024 * 1024 * 1024)),
        TB: "Terabytes: " + formatInt(bytes / (1024 * 1024 * 1024 * 1024)),
      });
    }
  };

  const formatInt = (inputInt) => {
    return new Intl.NumberFormat().format(inputInt);
  };

  const convertToBytes = () => {
    const value = parseFloat(dataConverterInput);
    if (!isNaN(value)) {
      switch (dataConverterSelect) {
        case "b":
          return value / 8;
        case "kb":
          return (value * 1024) / 8;
        case "mb":
          return (value * 1024 * 1024) / 8;
        case "gb":
          return (value * 1024 * 1024 * 1024) / 8;
        case "tb":
          return (value * 1024 * 1024 * 1024 * 1024) / 8;
        case "B":
          return value;
        case "KB":
          return value * 1024;
        case "MB":
          return value * 1024 * 1024;
        case "GB":
          return value * 1024 * 1024 * 1024;
        case "TB":
          return value * 1024 * 1024 * 1024 * 1024;
        default:
          return NaN;
      }
    }
    return NaN;
  };

  const calculateDataTime = () => {
    let fileSizeBytes = parseFloat(downloadFileSizeInput);
    if (downloadFileSizeDropdown === "KB") {
      fileSizeBytes *= 1024;
    } else if (downloadFileSizeDropdown === "MB") {
      fileSizeBytes *= 1024 * 1024;
    } else if (downloadFileSizeDropdown === "GB") {
      fileSizeBytes *= 1024 * 1024 * 1024;
    } else if (downloadFileSizeDropdown === "TB") {
      fileSizeBytes *= 1024 * 1024 * 1024 * 1024;
    }

    let bandwidthBps = parseFloat(downloadBandwidthInput);
    if (downloadBandwidthDropdown === "kb/s") {
      bandwidthBps *= 1000;
    } else if (downloadBandwidthDropdown === "mb/s") {
      bandwidthBps *= 1000000;
    } else if (downloadBandwidthDropdown === "gb/s") {
      bandwidthBps *= 1000000000;
    } else if (downloadBandwidthDropdown === "tb/s") {
      bandwidthBps *= 1000000000000;
    }

    const downloadTimeSeconds = (fileSizeBytes * 8) / bandwidthBps;
    const timeResultsString = secondsToString(downloadTimeSeconds);
    setDownloadTimeResults(timeResultsString);
  };

  const secondsToString = (totalSeconds) => {
    const hours = roundNumber(Math.floor(totalSeconds / 3600));
    const minutes = roundNumber(Math.floor((totalSeconds % 3600) / 60));
    const seconds = roundNumber(totalSeconds % 60);

    const hoursString = hours > 0 ? `${hours} hour${hours > 1 ? "s" : ""}` : "";
    const minutesString =
      minutes > 0 ? `${minutes} minute${minutes > 1 ? "s" : ""}` : "";
    const secondsString =
      seconds > 0 ? `${seconds} second${seconds > 1 ? "s" : ""}` : "";

    const timeString =
      `${hoursString} ${minutesString} ${secondsString}`.trim();

    return timeString;
  };

  const roundNumber = (number) => {
    if (!isNaN(number)) {
      if (Number.isInteger(number)) {
        return number;
      } else {
        return parseFloat(number).toFixed(2);
      }
    } else {
      return "Invalid input";
    }
  };

  const calculateWebsiteBandwidth = () => {
    setBandwidthWithoutRedundancy(
      (pageSizeInput * 8 * websitePageViewInput) / (30 * 1024 * 1024)
    );
    setBandwidthWithRedundancy(bandwidthWithoutRedundancy * redundancyInput);
    setBandwidthGBWithoutRedundancy((bandwidthWithoutRedundancy * 30) / 1024);
    setBandwidthGBWithRedundancy((bandwidthWithRedundancy * 30) / 1024);
  };

  return (
    <Content
      title="Bandwidth Calculator"
      description="Optimize your internet usage with our bandwidth calculators.
      Tailor your connectivity for optimal performance."
    >
      <div className="top-slice-container">
        <Breadcrumb />
        <h1>Bandwidth Calculator</h1>
        <div>
          <h2>Data Unit Converter</h2>
          {dataConverterResults.length > 0 ? (
            <div className="bandwidth-results-text">
              Results: {dataConverterResults}
            </div>
          ) : null}
          <input
            type="text"
            className="calculator-input"
            value={dataConverterInput}
            onChange={(e) => {
              setDataConverterInput(e.target.value);
            }}
          />
          <select
            value={dataConverterSelect}
            className="calculator-select"
            onChange={(e) => {
              setDataConverterSelect(e.target.value);
            }}
          >
            <option value="b">bits (b)</option>
            <option value="kb">kilobits (kb)</option>
            <option value="mb">megabits (mb)</option>
            <option value="gb">gigabits (gb)</option>
            <option value="tb">terabits (tb)</option>
            <option value="B">Bytes (B)</option>
            <option value="KB">Kilobytes (KB)</option>
            <option value="MB" selected="">
              Megabytes (MB)
            </option>
            <option value="GB">Gigabytes (GB)</option>
            <option value="TB">Terabytes (TB)</option>
          </select>
          {dataConverterResults.b ? (
            <div className="bandwidth-data-unit-results">
              {dataConverterInput} {dataConverterSelect} is equal to:
              <ul className="bandwidth-data-unit-results-list">
                {Object.entries(dataConverterResults).map(([unit, value]) => (
                  <li key={unit}>
                    {value} {unit}
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
          <button className="calculate-button" onClick={calculateDataUnit}>
            Calculate
          </button>
        </div>
        <div>
          <h2>Download/Upload Time Calculator</h2>
          <div>
            {downloadTimeResults ? (
              <div className="bandwidth-results-text">
                Results: {downloadTimeResults}
              </div>
            ) : null}
            File size:
            <input
              type="text"
              className="calculator-input"
              value={downloadFileSizeInput}
              onChange={(e) => {
                setDownloadFileSizeInput(e.target.value);
              }}
            />
            <select
              value={downloadFileSizeDropdown}
              className="calculator-select"
              onChange={(e) => {
                setDownloadFileSizeDropdown(e.target.value);
              }}
            >
              <option value="B">Bytes (B)</option>
              <option value="KB">Kilobytes (KB)</option>
              <option value="MB" selected="">
                Megabytes (MB)
              </option>
              <option value="GB">Gigabytes (GB)</option>
              <option value="TB">Terabytes (TB)</option>
            </select>
          </div>

          <div>
            Bandwidth:
            <input
              type="text"
              className="calculator-input"
              value={downloadBandwidthInput}
              onChange={(e) => {
                setDownloadBandwidthInput(e.target.value);
              }}
            />
            <select
              value={downloadBandwidthDropdown}
              className="calculator-select"
              onChange={(e) => {
                setDownloadBandwidthDropdown(e.target.value);
              }}
            >
              <option value="b/s">bits/s</option>
              <option value="kb/s">Kbits/s</option>
              <option value="mb/s">Mbits/s</option>
              <option value="gb/s">Gbits/s</option>
              <option value="tb/s">Tbits/s</option>
            </select>
            <button className="calculate-button" onClick={calculateDataTime}>
              Calculate
            </button>
          </div>
        </div>

        <div>
          <h2>Website Bandwidth Calculator</h2>
          <div>
            {bandwidthWithoutRedundancy ? (
              <div className="bandwidth-results-text">
                <div>
                  <p>
                    Actual bandwidth needed is {bandwidthWithoutRedundancy}{" "}
                    Mbits/s or {bandwidthGBWithoutRedundancy} GB per month.
                  </p>
                </div>
                <div>
                  {redundancyInput ? (
                    <div>
                      With redundancy factor of {redundancyInput}, the bandwidth
                      needed is {bandwidthWithRedundancy} Mbits/s or{" "}
                      {bandwidthGBWithRedundancy} GB per month.
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
            Page Views:
            <input
              type="text"
              className="calculator-input"
              value={websitePageViewInput}
              onChange={(e) => {
                setWebsitePageView(e.target.value);
              }}
            />
            <select
              value={bandwidthPageViewDropdown}
              className="calculator-select"
              onChange={(e) => {
                setBandwidthPageViewDropdown(e.target.value);
              }}
            >
              <option value="second">Per Second</option>
              <option value="minute">Per Minute</option>
              <option value="hour">Per Hour</option>
              <option value="day">Per Day</option>
              <option value="week">Per Week</option>
              <option value="month">Per Month</option>
              <option value="year">Per Year</option>
            </select>
          </div>

          <div>
            Average Page Size:
            <input
              type="text"
              className="calculator-input"
              value={pageSizeInput}
              onChange={(e) => {
                setPageSizeInput(e.target.value);
              }}
            />
            <select
              value={pageSizeDropdown}
              className="calculator-select"
              onChange={(e) => {
                setPageSizeDropdown(e.target.value);
              }}
            >
              <option value="B">Bytes (B)</option>
              <option value="KB">Kilobytes (KB)</option>
              <option value="MB">Megabytes (MB)</option>
              <option value="GB">Gigabytes (GB)</option>
              <option value="TB">Terabytes (TB)</option>
            </select>
          </div>

          <div>
            Redundancy:
            <input
              type="text"
              className="calculator-input"
              value={redundancyInput}
              onChange={(e) => {
                setRedundancy(e.target.value);
              }}
            />
          </div>
          <button
            className="calculate-button"
            onClick={calculateWebsiteBandwidth}
          >
            Calculate
          </button>
        </div>
      </div>

      <div className="info-paragraphs margin-auto">
        <h2>Introduction</h2>
        <p>
          Bandwidth is the lifeline of the internet, dictating how much data can
          be transmitted over a network within a given time frame. It influences
          everything from the speed at which web pages load to the quality of
          video streaming and the responsiveness of online gaming. Understanding
          your bandwidth needs is akin to knowing the capacity of a pipe
          carrying water – it determines how much data can flow through your
          connection at any given moment.
        </p>

        <p>
          Our bandwidth calculator simplifies this complex concept by allowing
          you to input relevant parameters such as the number of users, types of
          online activities, and desired quality levels. Whether you're a casual
          user checking emails and browsing social media or a power user engaged
          in data-intensive tasks like video conferencing or file sharing, our
          calculator provides personalized insights tailored to your usage
          profile.
        </p>

        <h2>Usage Scenarios</h2>
        <p>
          Small Office Network: For businesses operating in today's digital
          landscape, a reliable and high-speed internet connection is essential
          for maintaining productivity and facilitating seamless communication.
          Our bandwidth calculator helps small office networks determine the
          optimal bandwidth needed to support daily operations, including email
          communication, web browsing, video conferencing, and cloud-based
          applications. By accurately assessing usage patterns and user numbers,
          businesses can ensure their network infrastructure is equipped to
          handle peak demands without sacrificing performance.
        </p>
        <p>
          Home Entertainment: In an era dominated by streaming services and
          smart devices, home entertainment setups have become increasingly
          sophisticated, requiring robust internet connections to deliver
          high-quality audiovisual experiences. Whether you're streaming 4K
          content on multiple devices simultaneously, gaming online with
          friends, or video chatting with family members, our bandwidth
          calculator assists in determining the bandwidth required to support
          your preferred activities. From adjusting streaming resolutions to
          optimizing router settings, we provide practical recommendations to
          enhance your home entertainment setup and minimize buffering or lag.
        </p>
        <p>
          Remote Work and Telecommuting: With the rise of remote work and
          telecommuting arrangements, individuals rely on stable internet
          connections to collaborate with colleagues, attend virtual meetings,
          and access corporate resources from anywhere in the world. Our
          bandwidth calculator assists remote workers in estimating their
          bandwidth needs for tasks such as video conferencing, file sharing,
          and accessing cloud-based applications. By ensuring adequate bandwidth
          allocation, individuals can stay productive and connected while
          working remotely, without experiencing disruptions or slowdowns.
        </p>

        <h2>Bandwidth Glossary</h2>
        <p>
          <b>Bandwidth:</b> Bandwidth refers to the maximum amount of data that
          can be transmitted over a network connection within a given time
          frame. It is typically measured in bits per second (bps), kilobits per
          second (kbps), megabits per second (Mbps), or gigabits per second
          (Gbps).
        </p>

        <p>
          <b>Throughput:</b> Throughput is the actual amount of data transferred
          successfully over a network within a specific period. It represents
          the effective transmission rate and is often lower than the maximum
          bandwidth due to factors such as network congestion, latency, and
          packet loss.
        </p>

        <p>
          <b>Latency:</b> Latency, also known as ping or response time, is the
          time it takes for a data packet to travel from its source to its
          destination and back. It is measured in milliseconds (ms) and is
          influenced by factors such as network congestion, distance between
          endpoints, and processing delays.
        </p>

        <p>
          <b>Packet Loss:</b> Packet loss occurs when data packets traveling
          across a network fail to reach their destination. It can result from
          network congestion, hardware failures, or errors in transmission.
          Packet loss can degrade network performance and impact the reliability
          of data delivery.
        </p>

        <p>
          <b>Mbps (Megabits per second):</b> Mbps is a unit of measurement used
          to express the speed of data transmission in millions of bits per
          second. It is commonly used to describe internet connection speeds,
          file transfer rates, and streaming quality.
        </p>

        <p>
          <b>Upload Speed:</b> Upload speed refers to the rate at which data is
          transmitted from a user's device to the internet or another network
          destination. It is measured in Mbps and is essential for activities
          such as uploading files, sending emails, and participating in video
          calls.
        </p>

        <p>
          <b>Download Speed:</b> Download speed indicates the rate at which data
          is received by a user's device from the internet or another network
          source. It is measured in Mbps and influences the speed at which web
          pages load, files download, and streaming content buffers.
        </p>
        <p>
          <b>Bandwidth Cap:</b> A bandwidth cap is a limit imposed by an
          internet service provider (ISP) on the amount of data that a user can
          transmit over their network connection within a specified billing
          cycle. Exceeding the bandwidth cap may result in additional charges or
          throttling of internet speeds.
        </p>
        <p>
          <b>Quality of Service (QoS):</b> Quality of Service is a mechanism
          used to prioritize certain types of network traffic over others to
          ensure optimal performance and reliability for critical applications.
          QoS settings can be configured to allocate bandwidth resources based
          on specific criteria such as traffic type, source, destination, and
          latency requirements.
        </p>
        <p>
          <b>Traffic Shaping:</b> Traffic shaping is a technique used to control
          the flow of network traffic by prioritizing, limiting, or shaping the
          bandwidth allocated to different types of data packets. It helps
          optimize network performance, reduce congestion, and ensure equitable
          distribution of resources.
        </p>
        <p>
          <b>ISP (Internet Service Provider):</b> An ISP is a company that
          provides individuals and organizations with access to the internet.
          ISPs offer various types of internet connections, including dial-up,
          DSL, cable, fiber-optic, and satellite, each with different bandwidth
          capabilities and pricing plans.
        </p>
        <p>
          <b>Router:</b> A router is a networking device that forwards data
          packets between computer networks. It serves as a gateway between a
          local area network (LAN) and the internet, directing traffic to its
          intended destinations based on IP addresses and network protocols.
          Routers may include features such as firewall protection, wireless
          connectivity, and bandwidth management capabilities.
        </p>
      </div>
    </Content>
  );
};
