import React, { useState } from "react";
import { Content } from "../../Content";
import "../../styles/calculator.css";
import Breadcrumb from "../../components/Breadcrumb";

export const TimeCalculatorPage = () => {
  const [inputTime, setInputTime] = useState("");
  const [hoursToAdd, setHoursToAdd] = useState(0);
  const [minutesToAdd, setMinutesToAdd] = useState(0);
  const [secondsToAdd, setSecondsToAdd] = useState(0);
  const [isSubtractMode, setIsSubtractMode] = useState(false);
  const [resultTime, setResultTime] = useState(null);

  const handleTimeChange = (e) => {
    setInputTime(e.target.value);
  };

  const handleHoursChange = (e) => {
    setHoursToAdd(e.target.value);
  };

  const handleMinutesChange = (e) => {
    setMinutesToAdd(e.target.value);
  };

  const handleSecondsChange = (e) => {
    setSecondsToAdd(e.target.value);
  };

  const toggleMode = () => {
    setIsSubtractMode(!isSubtractMode);
  };

  const calculateTime = () => {
    const inputTimeObject = new Date(`1970-01-01T${inputTime}`);

    if (!isNaN(inputTimeObject.getTime())) {
      const resultTimeObject = new Date(inputTimeObject);
      const hoursModifier = isSubtractMode ? -1 : 1;
      const minutesModifier = isSubtractMode ? -1 : 1;
      const secondsModifier = isSubtractMode ? -1 : 1;

      resultTimeObject.setHours(
        resultTimeObject.getHours() + parseInt(hoursToAdd, 10) * hoursModifier
      );
      resultTimeObject.setMinutes(
        resultTimeObject.getMinutes() +
          parseInt(minutesToAdd, 10) * minutesModifier
      );
      resultTimeObject.setSeconds(
        resultTimeObject.getSeconds() +
          parseInt(secondsToAdd, 10) * secondsModifier
      );

      const resultHours = resultTimeObject
        .getHours()
        .toString()
        .padStart(2, "0");
      const resultMinutes = resultTimeObject
        .getMinutes()
        .toString()
        .padStart(2, "0");
      const resultSeconds = resultTimeObject
        .getSeconds()
        .toString()
        .padStart(2, "0");

      setResultTime(`${resultHours}:${resultMinutes}:${resultSeconds}`);
    } else {
      setResultTime(null);
    }
  };

  return (
    <Content
      title="Time Calculator"
      description="Empower your scheduling with our reliable time calculator.
      Effortlessly compute time intervals for better planning."
    >
      <div className="top-slice-container">
        <Breadcrumb />
        <h1>Time Calculator</h1>
        <div>
          <label>Input Time: </label>
          <input
            type="time"
            className="calculator-input"
            value={inputTime}
            onChange={handleTimeChange}
          />
        </div>
        <div>
          <label>Hours to Add/Subtract: </label>
          <input
            className="calculator-input"
            type="number"
            value={hoursToAdd}
            onChange={handleHoursChange}
          />
        </div>
        <div>
          <label>Minutes to Add/Subtract: </label>
          <input
            className="calculator-input"
            type="number"
            value={minutesToAdd}
            onChange={handleMinutesChange}
          />
        </div>
        <div>
          <label>Seconds to Add/Subtract: </label>
          <input
            className="calculator-input"
            type="number"
            value={secondsToAdd}
            onChange={handleSecondsChange}
          />
        </div>
        <div>
          <label>Operation: </label>
          <button className="calculate-button" onClick={toggleMode}>
            {isSubtractMode ? "Subtract" : "Add"}
          </button>
        </div>
        <button className="calculate-button" onClick={calculateTime}>
          Calculate
        </button>
        {resultTime && (
          <div>
            <h3>Result Time:</h3>
            <p>{resultTime}</p>
          </div>
        )}
      </div>

      <div className="info-paragraphs margin-auto">
        <h2>Measurement of Time</h2>
        <p>
          The measurement of time is fundamental to human civilization,
          providing a framework for organizing and understanding the passage of
          events and phenomena. Throughout history, various units have been used
          to measure time, ranging from the smallest increments of seconds to
          larger intervals such as years and centuries. These units allow for
          precise temporal calculations and scheduling, enabling coordination
          and synchronization across diverse activities and societies.
        </p>
        <p>
          One of the earliest methods of measuring time was through the
          observation of natural phenomena such as the movement of celestial
          bodies. Ancient civilizations, such as the Egyptians and Babylonians,
          used sundials to track the position of the sun and divide the day into
          hours. Water clocks, which measured time by the flow of water from one
          container to another, were also employed in ancient cultures to mark
          the passage of time.
        </p>
        <p>
          As societies evolved, more sophisticated timekeeping devices were
          developed. Mechanical clocks, invented in the Middle Ages, utilized
          gears and escapements to regulate the movement of clock hands,
          allowing for more accurate timekeeping. The pendulum clock, introduced
          in the 17th century, further improved accuracy and precision in time
          measurement.
        </p>
        <p>
          In the modern era, atomic clocks have become the standard for precise
          timekeeping. Atomic clocks measure time based on the vibrations of
          atoms, particularly cesium or rubidium atoms, which oscillate at
          extremely regular frequencies. These clocks are used as the basis for
          international time standards, such as Coordinated Universal Time
          (UTC), ensuring global synchronization of timekeeping.
        </p>
        <p>
          Time measurement is not only a practical necessity but also a subject
          of scientific inquiry. Physicists study the nature of time and its
          relationship to space, exploring concepts such as time dilation,
          time's arrow, and the possibility of time travel. Understanding the
          fundamental properties of time is essential for developing theories in
          fields such as relativity and quantum mechanics.
        </p>
        <p>
          In everyday life, time measurement plays a crucial role in various
          aspects of society, including transportation, communication, commerce,
          and science. It facilitates the coordination of schedules, the
          synchronization of processes, and the recording of historical events.
          The ability to measure time accurately has revolutionized human
          society, shaping the way we perceive and interact with the world
          around us.
        </p>

        <h2>Historical and Cultural Perspectives</h2>
        <p>
          Historical and cultural perspectives of time provide fascinating
          insights into how different societies have conceptualized and measured
          time throughout history. Across cultures, time has been viewed not
          only as a practical tool for organizing daily life but also as a
          philosophical, spiritual, and social construct deeply intertwined with
          cultural beliefs and values.
        </p>
        <p>
          In ancient civilizations such as Mesopotamia, Egypt, and China, time
          was often seen as cyclical, following natural rhythms such as the
          seasons, lunar cycles, and agricultural patterns. These cultures
          developed calendars based on astronomical observations to track time
          and plan religious rituals, agricultural activities, and
          administrative duties.
        </p>
        <p>
          In contrast, ancient Greek and Roman cultures introduced a more linear
          conception of time, influenced by philosophical and historical
          narratives that emphasized progress, change, and the passage of ages.
          This linear view of time, with a past, present, and future, laid the
          groundwork for the development of historical chronicles and narratives
          of human history.
        </p>
        <p>
          Religious traditions have also shaped cultural perspectives of time.
          In Judeo-Christian traditions, time is often seen as linear, with a
          beginning (creation) and an end (apocalypse), reflecting theological
          beliefs about divine providence and human destiny. Other religious
          traditions, such as Hinduism and Buddhism, incorporate cyclical
          notions of time, with recurring cycles of birth, death, and rebirth
          (samsara) or cosmic ages (yugas).
        </p>
        <p>
          Cultural attitudes toward punctuality, deadlines, and schedules vary
          widely across societies. Some cultures place a strong emphasis on
          punctuality and adherence to schedules as a sign of respect and
          efficiency, while others prioritize flexibility, spontaneity, and the
          flow of social interactions over strict timekeeping.
        </p>
        <p>
          Historical events, technological advancements, and global interactions
          have also influenced cultural perspectives of time. The industrial
          revolution, for example, introduced new concepts of time discipline
          and labor management, leading to the standardization of time zones and
          the adoption of synchronized timekeeping systems.
        </p>
        <p>
          Overall, historical and cultural perspectives of time highlight the
          diverse ways in which humans have conceptualized and experienced the
          passage of time. By examining these perspectives, we gain a deeper
          understanding of the complex interplay between time, culture, and
          society, and how it shapes our collective understanding of the past,
          present, and future.
        </p>

        <h2>Timekeeping Technologies</h2>
        <p>
          Timekeeping technologies have evolved significantly over the
          centuries, reflecting humanity's quest for precision, efficiency, and
          synchronization in measuring time. From ancient sundials to modern
          atomic clocks, these innovations have played a crucial role in shaping
          human activities, scientific advancements, and societal organization.
        </p>
        <p>
          One of the earliest timekeeping technologies was the sundial, which
          dates back to ancient civilizations such as the Egyptians, Greeks, and
          Romans. Sundials used the position of the sun's shadow to indicate the
          time of day, with markings or numerals inscribed on a flat surface.
          While simple in design, sundials provided a reliable method for
          dividing daylight hours and were widely used until the invention of
          mechanical clocks.
        </p>
        <p>
          The invention of mechanical clocks in the Middle Ages revolutionized
          timekeeping. Early mechanical clocks, such as tower clocks and
          domestic clocks, utilized gears, weights, and escapements to regulate
          the movement of clock hands. These clocks provided a more consistent
          and accurate way to measure time, allowing for standardized schedules
          and coordination of activities in medieval towns and cities.
        </p>
        <p>
          The pendulum clock, invented by Dutch scientist Christiaan Huygens in
          the 17th century, further improved accuracy in timekeeping. The
          pendulum's regular oscillations provided a precise mechanism for
          regulating the movement of clock hands, resulting in more reliable
          timepieces that were used for scientific experiments, navigation, and
          astronomy.
        </p>
        <p>
          The 20th century saw the development of electronic and atomic clocks,
          marking another leap forward in timekeeping technology. Electronic
          clocks replaced mechanical components with electronic circuits,
          offering greater precision and stability. Atomic clocks, which measure
          time based on the vibrations of atoms, became the gold standard for
          accurate timekeeping, with uncertainties of only a few billionths of a
          second per day.
        </p>
        <p>
          Today, atomic clocks are used as the basis for international time
          standards such as Coordinated Universal Time (UTC), ensuring global
          synchronization of timekeeping. These clocks are utilized in a wide
          range of applications, including satellite navigation systems,
          telecommunications networks, scientific research, and financial
          transactions.
        </p>
        <p>
          The evolution of timekeeping technologies reflects humanity's ongoing
          pursuit of precision and efficiency in measuring time. From ancient
          sundials to atomic clocks, these innovations have transformed the way
          we perceive and organize time, shaping the course of human history and
          facilitating progress in science, technology, and society.
        </p>

        <h2>Physics of Time</h2>
        <p>
          In the realm of physics, time is a fundamental concept that plays a
          central role in our understanding of the universe. From classical
          mechanics to modern theories of relativity and quantum mechanics,
          physicists have grappled with the nature of time and its relationship
          to space, matter, and energy.
        </p>
        <p>
          One of the key insights of classical mechanics, developed by Isaac
          Newton in the 17th century, is the concept of absolute time. According
          to Newtonian physics, time is an independent and universal quantity
          that progresses uniformly and continuously, regardless of the motion
          or state of objects in the universe. This classical view of time
          provided a framework for understanding the dynamics of mechanical
          systems and predicting the behavior of objects in motion.
        </p>
        <p>
          However, the advent of Albert Einstein's theory of relativity in the
          early 20th century revolutionized our understanding of time. In his
          theory of special relativity, Einstein proposed that time is relative
          and can vary depending on the relative motion of observers. According
          to the theory, time dilation occurs when objects move at speeds
          approaching the speed of light, causing time to appear to pass more
          slowly for observers in motion relative to those at rest.
        </p>
        <p>
          Einstein's theory of general relativity further expanded our
          understanding of time by incorporating gravity into the fabric of
          spacetime. According to general relativity, massive objects such as
          stars and planets warp the fabric of spacetime, causing time to be
          experienced differently in regions of strong gravitational fields.
          This phenomenon, known as gravitational time dilation, has been
          confirmed through experiments such as the observation of time
          discrepancies between clocks on Earth's surface and in orbit.
        </p>
        <p>
          In the realm of quantum mechanics, time plays a different role,
          appearing as a parameter in the equations that describe the behavior
          of subatomic particles. Quantum mechanics introduces the concept of
          quantum superposition, where particles can exist in multiple states
          simultaneously, and the notion of entanglement, where the properties
          of particles become correlated regardless of the distance between
          them. These quantum phenomena challenge our intuitive understanding of
          time and suggest that time may behave differently at the quantum
          level.
        </p>
        <p>
          Overall, the physics of time continues to be a subject of intense
          research and debate, with implications for our understanding of the
          nature of reality, the origins of the universe, and the fundamental
          laws that govern the cosmos. As physicists delve deeper into the
          mysteries of time, they seek to unravel its complexities and uncover
          the underlying principles that govern its behavior.
        </p>
      </div>
    </Content>
  );
};
