import React, { useState } from "react";
import { Content } from "../../Content";
import Breadcrumb from "../../components/Breadcrumb";
import "../../styles/calculator.css";

export const InterestRateCalculatorPage = () => {
  const [principal, setPrincipal] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [time, setTime] = useState("");
  const [result, setResult] = useState(null);

  const handlePrincipalChange = (e) => {
    setPrincipal(e.target.value);
  };

  const handleInterestRateChange = (e) => {
    setInterestRate(e.target.value);
  };

  const handleTimeChange = (e) => {
    setTime(e.target.value);
  };

  const calculateFutureValue = () => {
    const principalAmount = parseFloat(principal);
    const interestRatePercent = parseFloat(interestRate) / 100;
    const timePeriod = parseFloat(time);

    if (
      !isNaN(principalAmount) &&
      !isNaN(interestRatePercent) &&
      !isNaN(timePeriod)
    ) {
      const futureValue =
        principalAmount * Math.pow(1 + interestRatePercent, timePeriod);
      setResult(futureValue.toFixed(2));
    } else {
      setResult(null);
    }
  };

  return (
    <Content
      title="Interest Rate Calculator"
      description="Calculate interest rates effortlessly with our powerful interest rate calculator. Take control of your finances with ease."
    >
      <div className="top-slice-container">
        <Breadcrumb />
        <h1>Interest Rate Calculator</h1>
        <div>
          <label>Principal Amount ($): </label>
          <input
            className="calculator-input"
            type="number"
            value={principal}
            onChange={handlePrincipalChange}
          />
        </div>
        <div>
          <label>Interest Rate (%): </label>
          <input
            className="calculator-input"
            type="number"
            value={interestRate}
            onChange={handleInterestRateChange}
          />
        </div>
        <div>
          <label>Time (years): </label>
          <input
            type="number"
            className="calculator-input"
            value={time}
            onChange={handleTimeChange}
          />
        </div>
        <button className="calculate-button" onClick={calculateFutureValue}>
          Calculate
        </button>
        {result !== null && (
          <div>
            <h3>Future Value:</h3>
            <p>${result}</p>
          </div>
        )}
      </div>

      <div className="info-paragraphs margin-auto">
        <h3>Simple Interest vs. Compound Interest</h3>
        <p>
          In simple interest, the interest is calculated solely on the initial
          principal amount, without considering any interest that accumulates
          over time. The formula for calculating simple interest is
          straightforward:
        </p>

        <p>Simple Interest=P×r×t</p>

        <p>
          where P is the principal amount, r is the interest rate per period,
          and t is the time the money is invested or borrowed for. Simple
          interest is commonly used for short-term loans or in situations where
          interest is not reinvested.
        </p>

        <p>
          Unlike simple interest, compound interest takes into account the
          interest that accumulates not only on the principal amount but also on
          the previously earned interest. This compounding effect results in
          exponential growth of the investment over time. The compound interest
          formula is given by:
        </p>

        <p>Compound Interest=P×(1+(r/n))^(nt)−P</p>
        <p>
          where n is the number of times interest is compounded per year, and t
          is the time the money is invested or borrowed for. Compound interest
          is the prevailing method for most long-term investments, such as
          savings accounts, certificates of deposit, and mortgages, as it allows
          for the exponential growth of wealth over time.
        </p>

        <h3>Nominal Interest Rate vs. Effective Interest Rate</h3>

        <p>
          The nominal interest rate and the effective interest rate are two
          distinct measures used in financial transactions to communicate the
          cost of borrowing or the return on investment. Understanding the
          difference between these rates is crucial for borrowers and investors
          to accurately assess the true financial impact of a transaction.
        </p>

        <h4>Nominal Interest Rate:</h4>
        <p>
          The nominal interest rate, often referred to as the "stated" or
          "quoted" rate, is the rate that is explicitly stated in a loan
          agreement or financial contract. It represents the base interest rate
          without considering compounding or other factors that may affect the
          overall cost of borrowing. The nominal interest rate provides a clear
          indication of the cost of funds but does not account for the frequency
          of compounding.
        </p>

        <h4>Effective Interest Rate (APR):</h4>
        <p>
          The effective interest rate, also known as the Annual Percentage Rate
          (APR), is a more comprehensive measure that considers the impact of
          compounding on the nominal interest rate. It reflects the true cost of
          borrowing by incorporating not only the stated interest rate but also
          any fees, charges, or compounding frequency associated with the loan.
          The APR provides a more accurate representation of the total cost over
          the life of a loan, making it a valuable tool for borrowers comparing
          different financing options.
        </p>

        <h3>Interest Rate and Investment Returns</h3>
        <p>
          Interest rates play a pivotal role in shaping investment returns,
          influencing the profitability and risk associated with various
          investment opportunities. Investors carefully evaluate interest rates
          as they impact the cost of capital, the attractiveness of different
          asset classes, and the overall returns on investments.
        </p>

        <h4>Cost of Capital:</h4>
        <p>
          Interest rates directly affect the cost of capital, which is the
          expense a company incurs to fund its operations and growth. When
          interest rates are low, borrowing costs are reduced, making it more
          affordable for businesses to finance projects and expansions. This can
          lead to increased investment in capital expenditures, research and
          development, and other ventures that contribute to economic growth.
        </p>

        <h4>Bond Investments:</h4>
        <p>
          Interest rates have a significant impact on bond investments. Bond
          prices and interest rates have an inverse relationship – as interest
          rates rise, bond prices tend to fall, and vice versa. Investors
          holding existing bonds may experience capital losses in a rising
          interest rate environment. Conversely, new bond issuances in a
          high-interest rate environment offer higher yields, attracting
          investors seeking better returns.
        </p>

        <h4>Equity Investments:</h4>
        <p>
          Interest rates indirectly influence equity investments. When interest
          rates are low, the cost of borrowing is reduced, leading to increased
          consumer spending and corporate profitability. This, in turn, can
          boost stock prices as companies generate higher earnings. Conversely,
          in a high-interest rate environment, borrowing becomes more expensive,
          potentially impacting corporate profits and dampening stock prices.
        </p>

        <h4>Risk and Return Trade-Off:</h4>
        <p>
          Investors face a risk and return trade-off influenced by interest
          rates. Higher interest rates generally come with increased returns but
          may also entail higher risks. Investors seeking higher yields might
          venture into riskier assets or markets. Conversely, lower interest
          rates may offer lower returns but are often associated with lower-risk
          investments.
        </p>

        <h3>Credit Cards and Compound Interest</h3>
        <p>
          Credit cards are a widely used financial tool that allows individuals
          to make purchases on credit. One significant aspect of credit cards
          that often catches cardholders off guard is the application of
          compound interest on unpaid balances. Understanding how compound
          interest works with credit cards is crucial for managing finances
          effectively and avoiding unnecessary debt accumulation.
        </p>

        <p>
          Credit card companies typically calculate interest on the outstanding
          balance using compound interest. Unlike simple interest, which is
          calculated only on the principal amount, compound interest takes into
          account both the principal and any accumulated interest that has not
          been paid. This means that if the full credit card balance is not paid
          off each month, interest is applied not just to the initial amount
          owed but also to any interest that has accrued over previous billing
          cycles.
        </p>

        <p>
          Credit card issuers often compound interest on a daily basis. This
          daily compounding can significantly increase the overall interest
          accrued on an outstanding balance. The daily compounding nature of
          credit card interest underscores the importance of timely payments to
          minimize the impact of accumulating interest.
        </p>

        <p>
          Credit card statements typically include a minimum payment
          requirement. However, making only the minimum payment may not be
          sufficient to cover the accumulating interest. As a result, even if a
          cardholder consistently makes minimum payments, the outstanding
          balance can continue to grow over time due to the compounding of
          interest.
        </p>

        <p>
          The compounding nature of credit card interest can create a snowball
          effect, where even a relatively small initial balance can grow
          significantly over time if not promptly addressed. This emphasizes the
          importance of responsible credit card usage, budgeting, and prompt
          repayment to prevent the escalation of debt due to compound interest.
        </p>

        <h3>Risk and Interest Rates</h3>
        <p>
          The relationship between risk and interest rates is a fundamental
          consideration in financial markets, influencing investment decisions,
          borrowing costs, and overall economic dynamics. Investors, lenders,
          and policymakers closely monitor this relationship as they navigate
          the complex landscape of financial risk.
        </p>

        <p>
          Interest rates are often directly linked to the level of risk
          associated with an investment or borrowing arrangement. In general,
          higher-risk ventures or borrowers are charged higher interest rates to
          compensate lenders for the increased probability of default.
          Conversely, lower-risk investments or borrowers may enjoy lower
          interest rates as lenders perceive them as safer bets.
        </p>

        <p>
          Credit risk, or the risk of default, is a critical factor in
          determining interest rates. Lenders assess the creditworthiness of
          borrowers, and interest rates are adjusted accordingly. Borrowers with
          higher credit scores are deemed less risky and may secure loans with
          lower interest rates, while those with lower credit scores may face
          higher interest rates due to the perceived increased risk of
          non-repayment.
        </p>

        <p>
          Interest rates also factor in inflation risk. Lenders seek to maintain
          a real return on their investments, accounting for the eroding effects
          of inflation on purchasing power. As inflation expectations rise,
          lenders may demand higher interest rates to preserve the real value of
          their money.
        </p>

        <h3>Time Value of Money</h3>
        <p>
          The time value of money is a fundamental concept in finance that
          recognizes the idea that the value of money changes over time. This
          principle is based on the premise that a sum of money today is worth
          more than the same sum in the future or vice versa. The time value of
          money is a crucial consideration in various financial decisions,
          including investments, loans, and budgeting.
        </p>

        <h4>Present Value and Future Value:</h4>
        <p>
          The time value of money is commonly expressed through the concepts of
          present value and future value. Present value represents the current
          worth of a future sum of money, considering a specific interest rate
          or discount rate. Future value, on the other hand, represents the
          value of a present sum of money at a specified date in the future,
          accounting for interest or investment returns.
        </p>

        <h3>Opportunity Cost</h3>
        <p>
          The time value of money is closely tied to the concept of opportunity
          cost. By investing or utilizing funds today, individuals or businesses
          forgo the potential benefits of using that money for other purposes in
          the future. The time value of money encourages a comparison of the
          potential returns or costs associated with different uses of funds
          over time.
        </p>

        <h3>Applications in Investments:</h3>
        <p>
          In the context of investments, the time value of money is a critical
          factor in decision-making. Investors assess the future value of their
          investments, considering factors such as compounding returns, interest
          rates, and the length of the investment horizon. This analysis helps
          investors make informed choices about where to allocate their capital
          for maximum returns.
        </p>

        <h3>Inflation and Purchasing Power:</h3>
        <p>
          Inflation is a key consideration when assessing the time value of
          money. Over time, inflation erodes the purchasing power of money.
          Therefore, when considering future cash flows or payments, individuals
          and businesses need to account for the impact of inflation on the real
          value of money.
        </p>
      </div>
    </Content>
  );
};
