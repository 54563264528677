import React, { useState } from "react";
import { Content } from "../../Content";
import Breadcrumb from "../../components/Breadcrumb";
import "../../styles/calculator.css";

export const AreaConverterPage = () => {
  const [inputValue, setInputValue] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("squareMeters");
  const [convertedValue, setConvertedValue] = useState(null);

  const convertArea = () => {
    const value = parseFloat(inputValue);

    if (isNaN(value)) {
      setConvertedValue("Invalid input");
      return;
    }

    if (selectedUnit === "squareMeters") {
      const squareFeet = value * 10.764;
      setConvertedValue(
        `${value} square meters = ${squareFeet.toFixed(2)} square feet`
      );
    } else {
      const squareMeters = value / 10.764;
      setConvertedValue(
        `${value} square feet = ${squareMeters.toFixed(2)} square meters`
      );
    }
  };

  return (
    <Content
      title="Area Converter"
      description="Effortlessly convert areas with our versatile calculator.
      Simplify unit conversions for any measurement, from square meters to acres and beyond."
    >
      <div className="top-slice-container">
        <Breadcrumb />
        <h1>Area Converter</h1>
        <div>
          <label>Area:</label>
          <input
            className="calculator-input"
            type="number"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </div>
        <div>
          <label>Unit:</label>
          <select
            className="calculator-select"
            value={selectedUnit}
            onChange={(e) => setSelectedUnit(e.target.value)}
          >
            <option value="squareMeters">Square Meters</option>
            <option value="squareFeet">Square Feet</option>
          </select>
        </div>
        <button className="calculate-button" onClick={convertArea}>
          Convert
        </button>
        {convertedValue !== null ? (
          <div>
            <h3>Result:</h3>
            <p>{convertedValue}</p>
          </div>
        ) : null}
      </div>

      <div className="info-paragraphs margin-auto">
        <h2>The Historical Evolution of Area Conversion:</h2>
        <p>
          The history of area conversion is closely tied to the development of
          measurement systems and the need for standardized units of land and
          space. Ancient civilizations, such as the Egyptians and Mesopotamians,
          employed local units for land measurement. The Greeks introduced the
          concept of square units, and the Roman Empire contributed to the use
          of standardized measurements. During the medieval period, various
          regional units emerged across Europe. The standardization of units and
          the development of the metric system in the 18th and 19th centuries
          significantly influenced area conversion, providing a global framework
          for measurement.
        </p>

        <h2>Nuances in Area Calculations:</h2>
        <p>
          While area calculations may seem straightforward, nuances arise in the
          interpretation and conversion of different units. One fundamental
          nuance involves understanding the relationship between linear and
          square units. Converting from one unit to another requires squaring or
          taking the square root, depending on the direction of conversion.
          Additionally, consideration must be given to the shape being measured;
          for irregular shapes, methods such as integration or advanced
          geometric formulas may be employed for accurate area calculations.
        </p>

        <h2>Traditional Units and Cultural Variations:</h2>
        <p>
          Traditional units of area, rooted in historical and cultural contexts,
          introduce complexity to area conversion. The use of acres, hectares,
          dunams, or other regional units reflects historical land-use
          practices. Conversion between these units involves understanding the
          historical context and regional variations. For example, an acre is
          not universally defined and may vary in size between countries.
          Recognizing these cultural nuances is essential for accurate area
          conversions and land transactions.
        </p>

        <h2>Conversion Challenges in Global Contexts:</h2>
        <p>
          Globalization and international trade necessitate standardized area
          conversions for seamless communication. Challenges arise when dealing
          with diverse measurement systems and units used in different
          countries. The metric system has become widely adopted for
          international transactions, but transitions between metric and
          imperial units, as well as the persistence of traditional units, can
          complicate area conversions. Harmonization efforts and standardized
          conversion tables aim to address these challenges and promote
          consistency in global transactions.
        </p>

        <h2>Technology and Automated Conversions:</h2>
        <p>
          Advancements in technology have significantly streamlined area
          conversions. Digital mapping, Geographic Information Systems (GIS),
          and online tools provide automated solutions for converting areas
          between different units. These technologies not only enhance accuracy
          but also facilitate real-time conversions based on user preferences.
          The ability to perform instant and precise area conversions
          contributes to efficiency in fields such as urban planning,
          agriculture, and environmental monitoring.
        </p>

        <h2>Contemporary Applications and Future Trends:</h2>
        <p>
          Area conversion remains a critical aspect of various disciplines, from
          real estate and construction to environmental management and
          scientific research. The integration of satellite imagery, artificial
          intelligence, and data analytics promises further advancements in
          accurate area measurements and conversions. As technology continues to
          evolve, addressing the complexities of area calculations and ensuring
          standardized conversions will play a crucial role in supporting global
          collaboration and sustainable development.
        </p>
      </div>
    </Content>
  );
};
