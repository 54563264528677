// CustomProcess.js
import React from "react";
import { Content } from "../Content";
import { ContactUsForm } from "../components/ContactUsForm";

export const ContactUsPage = () => {
  return (
    <Content
      title="Contact Us"
      description="Reach out to us with any inquiries or feedback. We're here to assist you. Contact us today.">
      <div className="center">
        <h1>Contact Us</h1>
        <h3>Please feel free to contact us regarding a bug, new calculator or custom work you want.</h3>
        <ContactUsForm />
      </div>
    </Content>
  );
};
