// Header.js
import React from "react";
import { Link } from "react-router-dom";
import "../App.css";

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-wrapper">
        <span className="footer-nav-container">
          <nav className="footer-nav">
            <Link to="" className="footer-link">
            Home
            </Link>
            <Link to="/about-us" className="footer-link">
            About Us
            </Link>
            <Link to="/about-us#terms-of-use" className="footer-link">
            Terms of Use
            </Link>
            <Link to="/about-us#privacy-policy" className="footer-link">
            Privacy Policy
            </Link>
            <Link to="/contact-us" className="footer-link">
            Contact Us
            </Link>
            © 2023 - 2024
            <Link to="" className="footer-link">
              CalcuCrafter.com
            </Link>
          </nav>
        </span>
      </div>
    </footer>
  );
};
