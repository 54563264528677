import React, { useState } from "react";
import { Content } from "../../Content";
import Breadcrumb from "../../components/Breadcrumb";
import "../../styles/calculator.css";

export const LogCalculatorPage = () => {
  const [value1, setValue1] = useState("2");
  const [value2, setValue2] = useState("e");
  const [result, setResult] = useState("");
  const [error, setError] = useState("");

  const handleCalculate = () => {
    const value1_t = value1 === "e" ? Math.E : parseFloat(value1);
    const value2_t = value2 === "e" ? Math.E : parseFloat(value2);
    const result_t = result === "e" ? Math.E : parseFloat(result);

    if (!validInputs()) {
      setError("Please provide two values");
    } else if (!validNumbers()) {
      setError("Please provide numbers");
    } else {
      setError("");
      if (isNaN(result_t) || (value1_t && value2_t)) {
        console.log("result");
        setResult(Math.log(value1_t) / Math.log(value2_t));
      } else if (isNaN(value1_t)) {
        console.log("value");
        setResult(Math.pow(value2_t, result_t));
      } else if (isNaN(value2_t)) {
        console.log("base");
        setResult(Math.pow(value1_t, 1 / result_t));
      } else {
        setResult("");
        setError("Could not compute");
      }
    }
  };

  function validInputs() {
    if (
      (value1 === "" && value2 === "") ||
      (value1 === "" && result === "") ||
      (value2 === "" && result === "")
    ) {
      return false;
    } else {
      return true;
    }
  }

  function validNumbers() {
    if (
      (isNaN(value1) && isNaN(value2)) ||
      (isNaN(value1) && isNaN(result)) ||
      (isNaN(value2) && isNaN(result))
    ) {
      return false;
    } else {
      return true;
    }
  }

  return (
    <Content
      title="Log Calculator"
      description="Power up your math skills with our logarithmic calculator.
      Solve equations effortlessly and explore logarithmic functions."
    >
      <div className="top-slice-container">
        <Breadcrumb />
        <h2>Log Calculator</h2>
        <table className="margin-auto">
          <tbody>
            <tr>
              <td className="bold-big">Log</td>
              <td>
                <input
                  type="text"
                  className="calculator-input"
                  value={value1}
                  onChange={(e) => {
                    setValue1(e.target.value);
                  }}
                />
                <div>
                  <input
                    type="text"
                    className="calculator-input"
                    value={value2}
                    onChange={(e) => {
                      setValue2(e.target.value);
                    }}
                  />
                </div>
              </td>
              <td className="bold-big">=</td>
              <td>
                <input
                  type="text"
                  className="calculator-input"
                  value={result}
                  onChange={(e) => {
                    setResult(e.target.value);
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        {error && <p style={{ color: "red" }}>{error}</p>}
        <button onClick={handleCalculate} className="calculate-button">
          Calculate
        </button>
      </div>
      <div className="info-paragraphs margin-auto">
        <h2>Introduction to Logarithms</h2>
        <p>
          Logarithms are powerful mathematical tools that play a fundamental
          role in various fields, from mathematics and physics to finance and
          computer science. At their core, logarithms provide a means to express
          relationships between quantities that grow or decrease exponentially.
          Essentially, a logarithm is the inverse operation of exponentiation.
          While exponential functions represent rapid growth or decay,
          logarithmic functions offer a way to measure and analyze these changes
          in a more manageable manner.
        </p>
        <p>
          One of the key features of logarithms is their ability to condense
          large or small numbers into more compact forms, making them easier to
          work with and interpret. This property is particularly useful when
          dealing with quantities that span multiple orders of magnitude, such
          as seismic activity, sound intensity, or population growth. By
          converting these values into logarithmic scales, we can gain insights
          into their relative magnitudes and patterns of change. For example,
          the Richter scale, which measures earthquake intensity, uses a
          logarithmic scale to represent the seismic energy released by an
          earthquake, allowing scientists and engineers to compare earthquakes
          of different magnitudes on a standardized scale.
        </p>
        <p>
          Moreover, logarithms find applications in various problem-solving
          scenarios, including exponential growth and decay problems, compound
          interest calculations, and algorithmic complexity analysis.
          Understanding logarithms enables individuals to tackle complex
          problems more efficiently and to interpret data more effectively. As
          we delve deeper into the world of logarithms, we uncover their
          significance in diverse fields and appreciate their role in
          simplifying mathematical models and facilitating insightful analyses.
          Thus, by grasping the fundamentals of logarithms, we gain a valuable
          tool for navigating the complexities of quantitative reasoning and
          problem-solving in an ever-expanding array of disciplines.
        </p>

        <h2>Logarithm Rules</h2>
        <p>
          Logarithm rules are essential tools in mathematics, enabling us to
          simplify complex expressions and solve equations efficiently. These
          rules govern the behavior of logarithmic functions and provide
          guidelines for manipulating logarithmic expressions. One of the
          fundamental rules is the product rule, which states that the logarithm
          of the product of two numbers is equal to the sum of the logarithms of
          the individual numbers. Mathematically, this can be expressed as
          log(ab) = log(a) + log(b), where 'a' and 'b' are positive numbers. By
          applying this rule, we can break down multiplication operations into
          simpler additions, making calculations more manageable.
        </p>
        <p>
          Similarly, the quotient rule dictates that the logarithm of the
          quotient of two numbers is equal to the difference of the logarithms
          of the individual numbers. In other words, log(a/b) = log(a) - log(b).
          This rule allows us to simplify division operations by converting them
          into subtraction operations. Additionally, the power rule states that
          the logarithm of a number raised to a power is equal to the product of
          the exponent and the logarithm of the base. Mathematically, this rule
          can be represented as log(a^b) = b * log(a). By applying this rule, we
          can extract exponents from logarithmic expressions, leading to more
          concise representations of mathematical relationships.
        </p>
        <p>
          Furthermore, the change of base formula provides a method for
          converting logarithms from one base to another. This formula is
          particularly useful when working with logarithms in different bases,
          as it allows for greater flexibility and compatibility in
          calculations. The change of base formula states that log_b(a) =
          log_c(a) / log_c(b), where 'a' is a positive number, 'b' is the
          original base, and 'c' is the desired base. By utilizing this formula,
          we can seamlessly transition between different bases and adapt
          logarithmic expressions to suit specific requirements or computational
          constraints. Overall, understanding and applying logarithm rules
          empower us to manipulate logarithmic expressions effectively and solve
          complex mathematical problems with confidence.
        </p>

        <h2>Applications of Logarithms</h2>
        <p>
          Logarithms find ubiquitous applications across various disciplines,
          serving as indispensable tools for understanding and analyzing
          phenomena that exhibit exponential growth or decay. One of the most
          prominent applications of logarithms is in finance, particularly in
          the calculation of compound interest. Compound interest formulas
          inherently involve exponential growth, and logarithms offer a
          convenient method for determining the time required for an investment
          to double or triple in value. By applying logarithmic principles,
          financial analysts can make informed decisions about investments,
          loans, and savings strategies, optimizing financial outcomes over
          time.
        </p>
        <p>
          In the realm of physics and engineering, logarithms play a crucial
          role in quantifying phenomena with exponential characteristics. For
          instance, the decibel scale, used to measure sound intensity, employs
          logarithms to express the ratio of two sound levels. Similarly,
          seismic activity is measured on the Richter scale, which utilizes
          logarithms to quantify the energy released by earthquakes. Logarithmic
          scales provide a standardized framework for comparing and interpreting
          data across a wide range of magnitudes, enabling scientists and
          engineers to assess risks, design structures, and mitigate potential
          hazards effectively.
        </p>
        <p>
          In chemistry, logarithms are integral to understanding the pH scale,
          which measures the acidity or alkalinity of a solution. The pH scale
          is logarithmic, with each unit representing a tenfold change in
          acidity or alkalinity. By employing logarithmic principles, chemists
          can accurately assess the concentration of hydrogen ions in a
          solution, predict chemical reactions, and control reaction rates.
          Logarithmic concepts also extend to biological sciences, where they
          are used to model population growth, enzyme kinetics, and drug
          metabolism, among other phenomena.
        </p>
        <p>
          Moreover, logarithms have applications in computer science and
          information theory, where they are employed to analyze algorithms,
          quantify information entropy, and optimize data compression
          techniques. In data analysis and modeling, logarithms provide a means
          of transforming skewed or exponentially distributed data into a more
          symmetrical form, facilitating statistical analysis and predictive
          modeling. As such, the versatility and utility of logarithms permeate
          diverse fields, empowering researchers, analysts, and decision-makers
          to unlock insights, solve problems, and innovate across a spectrum of
          domains.
        </p>

        <h2>Logarithmic Scales</h2>
        <p>
          Logarithmic scales play a pivotal role in representing and
          interpreting data that span a wide range of magnitudes, from the
          microscopic to the astronomical. These scales offer a means of
          compressing expansive datasets into more manageable forms while
          preserving the relative relationships between values. One of the most
          well-known applications of logarithmic scales is in the measurement of
          sound intensity using the decibel (dB) scale. The decibel scale is
          logarithmic, with each increase of 10 dB representing a tenfold
          increase in sound intensity. By employing a logarithmic scale, the
          decibel scale enables us to quantify and compare sound levels across a
          broad spectrum of intensities, from the faintest whisper to the
          loudest roar.
        </p>
        <p>
          Similarly, logarithmic scales are employed in the field of seismology
          to measure earthquake magnitudes on the Richter scale. The Richter
          scale is logarithmic, with each whole number increase representing a
          tenfold increase in seismic energy. For example, an earthquake with a
          magnitude of 6.0 releases 10 times more energy than one with a
          magnitude of 5.0. By utilizing a logarithmic scale, the Richter scale
          provides a standardized framework for assessing earthquake magnitudes
          and communicating seismic risk to the public and policymakers.
        </p>
        <p>
          Logarithmic scales also find applications in the realm of finance,
          particularly in the representation of stock prices and market indices.
          Stock price movements are often depicted on logarithmic charts, where
          equal percentage changes in price are represented by equal distances
          on the vertical axis. This logarithmic representation allows traders
          and investors to visualize long-term trends and identify patterns in
          price movements more effectively. Additionally, logarithmic scales are
          utilized in the measurement of pH levels in chemistry, where each unit
          change on the pH scale represents a tenfold change in acidity or
          alkalinity.
        </p>

        <h2>Graphing Logarithmic Functions</h2>
        <p>
          Graphing logarithmic functions is an essential skill in mathematics
          and provides valuable insights into the behavior of exponential
          relationships. Logarithmic functions have unique characteristics that
          distinguish them from other types of functions, making their graphs
          distinctive and informative. One of the fundamental properties of
          logarithmic functions is that they have vertical asymptotes, meaning
          that their graphs approach but never touch certain vertical lines.
          These asymptotes occur at x = 0 for logarithmic functions with a base
          greater than 1 and at x = 0 for logarithmic functions with a base
          between 0 and 1.
        </p>
        <p>
          Moreover, the domain of a logarithmic function is restricted to
          positive real numbers, as logarithms are undefined for non-positive
          arguments. Consequently, the graph of a logarithmic function is
          typically limited to the positive x-axis, with the function values
          increasing or decreasing rapidly as x approaches infinity or zero,
          respectively. Additionally, logarithmic functions exhibit reflection
          symmetry about their vertical asymptotes, resulting in mirror-image
          behavior on either side of the asymptotic line.
        </p>
        <p>
          When graphing logarithmic functions, it is crucial to consider the
          effects of variations in the base of the logarithm. Changes in the
          base value alter the steepness and positioning of the graph, with
          larger base values leading to steeper curves and smaller base values
          yielding flatter curves. Additionally, transformations such as shifts,
          stretches, and compressions can be applied to the standard logarithmic
          function y = log_b(x) to produce a wide range of graph shapes and
          configurations.
        </p>
        <p>
          Graphing software and calculators equipped with logarithm capabilities
          provide valuable tools for visualizing and analyzing logarithmic
          functions. These tools allow users to input specific function
          parameters, such as the base value and any transformations, and
          generate accurate graphs instantaneously. By examining the resulting
          graphs, mathematicians, scientists, and engineers can gain deeper
          insights into the properties and behavior of logarithmic functions,
          enabling them to make informed decisions and solve complex problems in
          their respective fields.
        </p>

        <h2>Solving Logarithmic Equations</h2>
        <p>
          Solving logarithmic equations involves applying logarithm rules and
          algebraic techniques to isolate the variable of interest. Logarithmic
          equations typically arise in various mathematical contexts, including
          exponential growth and decay problems, compound interest calculations,
          and algorithmic complexity analysis. One of the key strategies for
          solving logarithmic equations is to use the properties of logarithms
          to rewrite the equation in a simpler form. For example, the product
          rule, quotient rule, and power rule can be utilized to manipulate
          logarithmic expressions and isolate terms containing the variable.
        </p>
        <p>
          When solving logarithmic equations, it is essential to consider the
          domain restrictions imposed by the logarithmic function. Since
          logarithms are undefined for non-positive arguments, it is necessary
          to ensure that any potential solutions satisfy the domain constraints
          of the logarithmic expression. In some cases, extraneous solutions may
          arise when taking logarithms of both sides of an equation, requiring
          careful scrutiny to identify and discard invalid solutions.
        </p>
        <p>
          Additionally, logarithmic equations may involve exponential terms,
          requiring the application of exponential properties to simplify the
          equation further. By converting logarithmic equations into exponential
          form and vice versa, mathematicians can leverage the familiarity of
          exponential functions to facilitate problem-solving and identify
          solutions efficiently. Graphical methods, such as plotting the graphs
          of the logarithmic and exponential functions involved in the equation,
          can also provide visual insights into the behavior of the equation and
          aid in identifying potential solutions.
        </p>
        <p>
          Furthermore, logarithmic equations may require iterative or numerical
          methods to obtain approximate solutions, particularly in cases where
          exact algebraic solutions are impractical or impossible to obtain.
          Numerical techniques, such as Newton's method or binary search
          algorithms, can be employed to approximate solutions with a desired
          level of accuracy. Logarithmic equations frequently arise in
          engineering, physics, finance, and computer science, where precise
          solutions are essential for modeling complex phenomena, optimizing
          systems, and making informed decisions.
        </p>
      </div>
    </Content>
  );
};
