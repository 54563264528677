import React, { useState } from "react";
import { Content } from "../../Content";
import Breadcrumb from "../../components/Breadcrumb";
import "../../styles/calculator.css";

export const HexCalculatorPage = () => {
  const [input1, setInput1] = useState("0xB78");
  const [input2, setInput2] = useState("0x8AB");
  const [operation, setOperation] = useState("add");
  const [calcResult, setCalculationResult] = useState("");

  const [inputHToD, setinputHToD] = useState("0xDAD");
  const [hToDResult, calcHToDResult] = useState("");

  const [inputDToH, setinputDToH] = useState("170");
  const [dToHResult, calcDToHResult] = useState("");

  const handleInputChange1 = (e) => {
    setInput1(e.target.value);
  };

  const handleInputChange2 = (e) => {
    setInput2(e.target.value);
  };

  const handleOperationChange = (e) => {
    setOperation(e.target.value);
  };

  const handleHToDInputChange = (e) => {
    setinputHToD(e.target.value);
  };

  const handleDToHInputChange = (e) => {
    setinputDToH(e.target.value);
  };

  const calculate = () => {
    if (
      !input1.match(/^(0x)?[0-9a-f]+$/i) ||
      !input2.match(/^(0x)?[0-9a-f]+$/i)
    ) {
      setCalculationResult("Invalid input. Please enter hexadecimal numbers.");
      return;
    }

    setInput1(formatHexidecimal(input1));
    setInput2(formatHexidecimal(input2));

    const num1 = parseInt(input1, 16);
    const num2 = parseInt(input2, 16);
    let resultValue;

    switch (operation) {
      case "add":
        resultValue = num1 + num2;
        break;
      case "subtract":
        resultValue = num1 - num2;
        break;
      case "multiply":
        resultValue = num1 * num2;
        break;
      case "divide":
        if (num2 === 0) {
          setCalculationResult("Cannot divide by zero.");
          return;
        }
        resultValue = num1 / num2;
        break;
      default:
        setCalculationResult("Invalid operation.");
        return;
    }

    setCalculationResult("0x" + resultValue.toString(16));
  };

  const calculateHToD = () => {
    const decimal = parseInt(inputHToD, 16);
    calcHToDResult(decimal);
  };

  const calculateDToH = () => {
    const hex = inputDToH.toString(16);
    calcDToHResult(hex);
  };

  const formatHexidecimal = (hexidecimal) => {
    if (!hexidecimal.startsWith("0x")) {
      return "0x" + hexidecimal;
    } else {
      return hexidecimal;
    }
  };

  return (
    <Content
      title="Hex Calculator"
      description="Perform hexadecimal arithmetic effortlessly.
      Add, subtract, multiply, or divide with precision. Elevate your computing experience now!"
    >
      <div className="top-slice-container">
        <Breadcrumb />
        <h1>Hex Calculator</h1>
        <div>
          <h2>Hexadecimal Calculation—Add, Subtract, Multiply, or Divide</h2>
          <input
            type="text"
            className="calculator-input"
            value={input1}
            onChange={handleInputChange1}
          />
          <select
            value={operation}
            className="calculator-select"
            onChange={handleOperationChange}
          >
            <option value="add">+</option>
            <option value="subtract">-</option>
            <option value="multiply">*</option>
            <option value="divide">÷</option>
          </select>
          <input
            type="text"
            className="calculator-input"
            value={input2}
            onChange={handleInputChange2}
          />
          {calcResult ? (
            <div className="bold-big">Result: {calcResult}</div>
          ) : null}
          <button className="calculate-button" onClick={calculate}>
            Calculate
          </button>
        </div>

        <div>
          <h2>Convert Hexadecimal Value to Decimal Value</h2>
          <input
            type="text"
            className="calculator-input"
            value={inputHToD}
            onChange={handleHToDInputChange}
          />
          {hToDResult ? (
            <div className="bold-big">Result: {hToDResult}</div>
          ) : null}
          <button className="calculate-button" onClick={calculateHToD}>
            Calculate
          </button>
        </div>

        <div>
          <h2>Convert Decimal Value to Hexadecimal Value</h2>
          <input
            type="text"
            className="calculator-input"
            value={inputDToH}
            onChange={handleDToHInputChange}
            placeholder="170"
          />
          {dToHResult ? (
            <div className="bold-big">Result: {dToHResult}</div>
          ) : null}
          <button className="calculate-button" onClick={calculateDToH}>
            Calculate
          </button>
        </div>
      </div>

      <div className="info-paragraphs margin-auto">
        <h2>Introduction</h2>
        <p>
          Hexadecimal, often shortened to "hex," is a base-16 numbering system
          that plays a crucial role in various aspects of digital technology,
          including programming, networking, and data representation. Unlike the
          decimal system we commonly use, which is base-10, hexadecimal utilizes
          sixteen distinct symbols, ranging from 0 to 9 and then A to F, where A
          represents the decimal value 10, B represents 11, and so forth. On
          this page, we provide you with a powerful hexadecimal calculator,
          allowing you to perform arithmetic operations, conversions, and
          manipulations effortlessly. Whether you're a seasoned programmer, a
          curious student, or someone eager to delve into the intricacies of
          digital mathematics, our calculator and accompanying resources will
          help you unlock the potential of hexadecimal numbers and their utility
          in the digital realm
        </p>

        <h2>Benefits of Hexadecimal in Computing</h2>
        <p>
          Hexadecimal computing offers several significant benefits in various
          aspects of digital technology, making it an indispensable tool for
          programmers, engineers, and computer scientists. One of the primary
          advantages of hexadecimal computing lies in its efficiency in
          representing binary data. Since hexadecimal is a base-16 system, each
          hexadecimal digit corresponds to exactly four binary digits (bits).
          This one-to-one mapping simplifies the representation of binary data,
          making it more concise and easier to work with, especially when
          dealing with large amounts of binary information. For example, a
          32-bit binary number can be represented by just eight hexadecimal
          digits, significantly reducing the visual complexity and aiding in
          readability.
        </p>

        <p>
          Another benefit of hexadecimal computing is its alignment with the
          memory architecture of most modern computers. Computer memory is
          typically organized in bytes, with each byte consisting of eight bits.
          Since a byte can represent two hexadecimal digits, memory addresses
          and data offsets are often expressed in hexadecimal notation. This
          alignment facilitates memory management, debugging, and low-level
          programming tasks, as hexadecimal representations correspond directly
          to memory locations and byte offsets within computer systems.
        </p>

        <p>
          Hexadecimal computing also simplifies bitwise operations and bitwise
          manipulation of binary data. In many programming languages and
          systems, bitwise operations are fundamental for tasks such as data
          encryption, compression, and protocol implementations. Hexadecimal
          notation provides a convenient way to visualize and perform bitwise
          operations, as each hexadecimal digit corresponds to a distinct
          combination of four bits. This granularity enables programmers to
          manipulate binary data at a fine-grained level, optimizing performance
          and enhancing the efficiency of their algorithms.
        </p>
        <p>
          Furthermore, hexadecimal computing is essential in various fields such
          as digital electronics, networking, and graphics programming. In
          digital electronics, hexadecimal notation is commonly used to
          represent hexadecimal numbers, register values, and memory addresses
          in hardware design and debugging. In networking, hexadecimal
          representations are prevalent in protocols such as IPv6 addresses and
          MAC addresses, facilitating communication and network administration
          tasks. In graphics programming, hexadecimal color codes are widely
          used to specify colors in web design, image processing, and computer
          graphics applications, providing a standardized and compact
          representation of RGB (Red, Green, Blue) color values.
        </p>
        <p>
          Overall, the benefits of hexadecimal computing stem from its efficient
          representation of binary data, alignment with computer memory
          architecture, support for bitwise operations, and widespread use in
          various digital technology domains. Whether you're a programmer,
          engineer, or enthusiast, understanding hexadecimal computing is
          essential for mastering the intricacies of digital systems and
          harnessing the full potential of modern computing technologies.
        </p>

        <h2>
          Hexadecimal Operations (Addition, Subtraction, Multiplication,
          Division)
        </h2>

        <p>
          Performing basic hexadecimal operations using a hexadecimal calculator
          is straightforward and efficient, empowering users to manipulate
          hexadecimal numbers with ease. Whether you're adding, subtracting,
          multiplying, or dividing hexadecimal values, the calculator simplifies
          the process, providing accurate results in a fraction of the time it
          would take to perform manually. Let's delve into the essential
          operations and how to execute them using the calculator.
        </p>
        <h3>Addition</h3>
        <p>
          To add two hexadecimal numbers, simply input the numbers into the
          calculator's input fields and select the addition operation. The
          calculator will then perform the addition and display the result in
          hexadecimal format. For example, to add 3F and A7, input '3F' and 'A7'
          into the respective fields, select the addition operation, and the
          calculator will output the result, such as 'E6'.
        </p>
        <h3>Subtraction</h3>
        <p>
          Subtracting hexadecimal numbers follows a similar process. Input the
          minuend (the number from which another number is to be subtracted) and
          the subtrahend (the number to be subtracted) into the calculator's
          input fields, select the subtraction operation, and the calculator
          will compute the difference. For instance, to subtract 5D from A9,
          input 'A9' as the minuend and '5D' as the subtrahend, select
          subtraction, and the calculator will display the result, such as '4C'.
        </p>
        <h3>Multiplication</h3>
        <p>
          Multiplying hexadecimal numbers involves inputting the multiplicand
          and multiplier into the calculator and selecting the multiplication
          operation. The calculator will then perform the multiplication and
          display the result in hexadecimal format. For example, to multiply 12
          and 3F, input '12' as the multiplicand and '3F' as the multiplier,
          select multiplication, and the calculator will output the result, such
          as '468'.
        </p>

        <h3>Division</h3>
        <p>
          Dividing hexadecimal numbers requires entering the dividend (the
          number to be divided) and the divisor (the number by which the
          dividend is to be divided) into the calculator's input fields and
          selecting the division operation. The calculator will compute the
          quotient and display the result in hexadecimal format. For instance,
          to divide 7E by 4, input '7E' as the dividend and '4' as the divisor,
          select division, and the calculator will output the result, such as
          '1F'.
        </p>
        <p>
          In addition to performing basic arithmetic operations, the hexadecimal
          calculator may also support other functionalities such as bitwise
          operations, logical operations, and conversions between hexadecimal,
          decimal, and binary representations. By mastering these basic
          operations and leveraging the capabilities of the calculator, users
          can efficiently manipulate hexadecimal numbers for various computing
          tasks, programming endeavors, and engineering applications. Whether
          you're a beginner exploring hexadecimal arithmetic or an experienced
          professional optimizing algorithms, the hexadecimal calculator serves
          as a valuable tool for numeric computation in the digital realm.
        </p>

        <h2>Converting Hexadecimal to Decimal</h2>
        <p>
          Converting hexadecimal numbers to decimal is a fundamental operation
          in digital computing, and with a hexadecimal calculator readily
          available on a page, the process becomes swift and straightforward.
          Hexadecimal numbers are base-16, meaning they use 16 distinct symbols
          (0-9 followed by A-F) to represent values. Decimal numbers, on the
          other hand, are base-10, utilizing 10 symbols (0-9) to represent
          values. Converting from hexadecimal to decimal involves understanding
          the positional value system and the relationship between each digit
          and its place value.
        </p>
        <p>
          To convert a hexadecimal number to decimal using the calculator,
          simply input the hexadecimal number into the designated field and
          select the conversion operation. The calculator will then compute the
          equivalent decimal value and display it. For example, to convert the
          hexadecimal number '2A' to decimal, input '2A' into the calculator,
          select the conversion operation, and the calculator will output the
          decimal equivalent, which is '42'.
        </p>
        <p>
          The conversion process relies on the positional value system, where
          each digit's position in the number represents a power of 16. Starting
          from the rightmost digit, the position values increase by powers of 16
          as you move towards the left. The rightmost digit represents 16^0 (1),
          the next digit to the left represents 16^1 (16), the next represents
          16^2 (256), and so on. Each hexadecimal digit can have a value ranging
          from 0 to 15, where A represents 10, B represents 11, and so forth up
          to F representing 15.
        </p>
        <p>
          When converting a hexadecimal number to decimal, multiply each digit
          by its corresponding power of 16 according to its position in the
          number, and then sum the results. The calculator automates this
          process, making it effortless to obtain the decimal equivalent of any
          hexadecimal number. This conversion is crucial for various computing
          tasks, such as programming, networking, and digital electronics, where
          decimal representations are often preferred for human readability and
          compatibility with other systems.
        </p>
        <p>
          By leveraging the capabilities of the hexadecimal calculator, users
          can quickly and accurately convert hexadecimal numbers to decimal,
          facilitating numeric computations and data analysis in the digital
          realm. Whether you're a programmer, engineer, or enthusiast exploring
          the intricacies of number systems, the hexadecimal calculator serves
          as a valuable tool for seamlessly transitioning between different
          representations and unlocking the full potential of hexadecimal
          computing.
        </p>

        <h2>Practical Uses of Hexadecimal in Programming and Engineering</h2>
        <p>
          Hexadecimal notation plays a crucial role in programming and
          engineering, offering practical advantages that streamline various
          tasks and facilitate efficient communication within the digital
          domain. Its versatility and compact representation make it
          indispensable in a wide range of applications across programming
          languages, hardware design, networking, and other engineering
          disciplines.
        </p>
        <p>
          In programming, hexadecimal is frequently used to represent memory
          addresses, binary data, and character encodings. Memory addresses in
          computer systems are commonly expressed in hexadecimal notation,
          providing a concise and intuitive way to reference specific locations
          in memory. This is particularly valuable in low-level programming
          tasks such as system programming and device driver development, where
          direct manipulation of memory is necessary. Additionally, hexadecimal
          notation is utilized in binary file formats, communication protocols,
          and cryptographic algorithms, where binary data needs to be
          represented and manipulated efficiently. For instance, hexadecimal is
          often employed in file headers, packet headers, and cryptographic
          keys, where its compact representation simplifies data exchange and
          processing.
        </p>
        <p>
          In hardware design and digital electronics, hexadecimal notation is
          essential for specifying register values, memory-mapped I/O addresses,
          and configuration settings in microcontrollers, field-programmable
          gate arrays (FPGAs), and other embedded systems. Engineers use
          hexadecimal values to configure hardware components, set control
          parameters, and troubleshoot system behavior. Hexadecimal notation
          also facilitates bitwise operations and signal processing algorithms,
          where individual bits need to be manipulated or analyzed at a granular
          level. Furthermore, hexadecimal color codes are widely used in graphic
          design, user interface development, and web programming, allowing
          designers and developers to specify colors with precision and
          consistency across different platforms and devices.
        </p>
        <p>
          Networking is another area where hexadecimal notation is pervasive,
          particularly in the representation of IP addresses, MAC addresses, and
          port numbers. IPv6 addresses, for example, are expressed in
          hexadecimal notation, making them more concise and readable compared
          to their IPv4 counterparts. Similarly, MAC addresses, which uniquely
          identify network interfaces, are typically represented as a series of
          hexadecimal digits, facilitating device identification and network
          administration tasks. Hexadecimal notation also simplifies the
          configuration of network devices, such as routers, switches, and
          firewalls, where hexadecimal values are often used to specify network
          settings and access control lists.
        </p>
        <p>
          Overall, the practical uses of hexadecimal in programming and
          engineering are diverse and far-reaching, spanning from low-level
          system programming to high-level application development, from digital
          circuit design to network administration. By leveraging the
          compactness and versatility of hexadecimal notation, programmers and
          engineers can efficiently manipulate data, configure hardware, and
          communicate complex concepts, ultimately contributing to the
          advancement of technology and innovation in the digital age.
        </p>
      </div>
    </Content>
  );
};
