import React, { useState } from "react";
import { Content } from "../../Content";
import Breadcrumb from "../../components/Breadcrumb";
import "../../styles/calculator.css";

export const LengthConverterPage = () => {
  const [inputValue, setInputValue] = useState("");
  const [inputUnit, setInputUnit] = useState("meters");
  const [outputUnit, setOutputUnit] = useState("centimeters");
  const [outputValue, setOutputValue] = useState(null);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setOutputValue(null); // Clear the output value when input value is changed
  };

  const handleInputUnitChange = (e) => {
    setInputUnit(e.target.value);
    setOutputValue(null); // Clear the output value when input unit is changed
  };

  const handleOutputUnitChange = (e) => {
    setOutputUnit(e.target.value);
    setOutputValue(null); // Clear the output value when output unit is changed
  };

  const convertLength = () => {
    const inputValueFloat = parseFloat(inputValue);

    if (!isNaN(inputValueFloat)) {
      const meters = {
        millimeters: inputValueFloat * 1000,
        centimeters: inputValueFloat * 100,
        meters: inputValueFloat,
        kilometers: inputValueFloat / 1000,
      };

      setOutputValue(meters[outputUnit].toFixed(2));
    }
  };

  return (
    <Content
      title="Length Converter"
      description="Effortlessly convert lengths with our versatile calculator.
      Simplify unit conversions for any measurement, from inches to kilometers."
    >
      <div className="top-slice-container">
        <Breadcrumb />
        <h1>Length Converter</h1>
        <div>
          <label>Input Length: </label>
          <input
            type="number"
            className="calculator-input"
            value={inputValue}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Input Unit: </label>
          <select
            className="calculator-select"
            value={inputUnit}
            onChange={handleInputUnitChange}
          >
            <option value="millimeters">Millimeters</option>
            <option value="centimeters">Centimeters</option>
            <option value="meters">Meters</option>
            <option value="kilometers">Kilometers</option>
          </select>
        </div>
        <div>
          <label>Output Unit: </label>
          <select
            className="calculator-select"
            value={outputUnit}
            onChange={handleOutputUnitChange}
          >
            <option value="millimeters">Millimeters</option>
            <option value="centimeters">Centimeters</option>
            <option value="meters">Meters</option>
            <option value="kilometers">Kilometers</option>
          </select>
        </div>
        <button className="calculate-button" onClick={convertLength}>
          Convert
        </button>
        {outputValue !== null && (
          <div>
            <h3>Converted Length:</h3>
            <p>
              {outputValue} {outputUnit}
            </p>
          </div>
        )}
      </div>

      <div className="info-paragraphs margin-auto">
        <h2>The Historical Evolution of Length Conversion:</h2>
        <p>
          The history of length conversion is intricately woven into the
          development of measurement systems throughout human civilization.
          Early civilizations devised local units for measuring lengths based on
          body parts or natural elements. Ancient cultures, such as the
          Egyptians and Mesopotamians, established their own cubits and spans.
          The Romans played a significant role in disseminating standardized
          units across their vast empire. The medieval period saw the emergence
          of various regional units, leading to a complex tapestry of
          measurements. The Renaissance marked a resurgence of interest in
          standardization, and the subsequent centuries witnessed the gradual
          adoption of global measurement systems, including the metric system in
          the 18th century.
        </p>

        <h2>Nuances in Length Calculations:</h2>
        <p>
          While calculating length may seem straightforward, nuances emerge in
          the conversion between different units and the interpretation of
          measurements. One fundamental nuance involves understanding the
          relationship between metric and imperial units. Converting between
          meters and feet, or centimeters and inches, requires attention to the
          conversion factor and rounding conventions. Additionally,
          consideration must be given to precision, especially in scientific and
          engineering contexts where accurate length measurements are crucial.
        </p>

        <h2>Imperial, Metric, and Cultural Variations:</h2>
        <p>
          The coexistence of imperial and metric systems adds complexity to
          length conversion. Different countries and industries may prefer one
          system over the other, leading to challenges in communication and
          trade. Cultural variations also contribute to the diversity of length
          units. For example, nautical miles are used in maritime contexts,
          while surveyors may employ chains or rods. Recognizing these
          variations is essential for accurate length conversions and effective
          collaboration across borders.
        </p>

        <h2>Advanced Techniques and Precision Instruments:</h2>
        <p>
          Advancements in technology have revolutionized length measurements.
          Precision instruments such as laser rangefinders, GPS devices, and
          interferometers enable highly accurate measurements in various fields.
          Calibration standards and metrology labs play a crucial role in
          maintaining the accuracy of measurement tools. The use of advanced
          techniques, such as interferometry and spectroscopy, allows for
          nanoscale precision in scientific research and industrial
          applications.
        </p>

        <h2>Challenges in Astronomical and Subatomic Scales:</h2>
        <p>
          Measuring lengths on astronomical and subatomic scales introduces
          unique challenges. Astronomers use light-years to describe vast cosmic
          distances, and particle physicists delve into femtometers to explore
          the smallest particles. The enormity and minuteness of these scales
          necessitate specialized units and sophisticated measurement
          techniques. In these realms, the understanding of relativity, quantum
          mechanics, and the limitations of classical measurement becomes
          paramount.
        </p>

        <h2>Integration of Length Measurement in Everyday Life:</h2>
        <p>
          Length conversion is an integral part of everyday life, influencing
          various aspects from construction and architecture to travel and
          consumer goods. The ubiquity of length measurements in diverse fields
          underscores the importance of standardized units and efficient
          conversion methods. As technology continues to advance, ensuring
          precision and promoting global standardization will be key factors in
          addressing the challenges and enhancing the accuracy of length
          measurements across different domains.
        </p>
      </div>
    </Content>
  );
};
