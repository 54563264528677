import React, { useState } from "react";
import { Content } from "../../Content";
import Breadcrumb from "../../components/Breadcrumb";
import "../../styles/calculator.css";

export const PercentageCalculatorPage = () => {
  const [baseNumber, setBaseNumber] = useState("");
  const [percentage, setPercentage] = useState("");
  const [result, setResult] = useState(null);

  const handleBaseNumberChange = (e) => {
    setBaseNumber(e.target.value);
    setResult(null); // Clear the result when the base number is changed
  };

  const handlePercentageChange = (e) => {
    setPercentage(e.target.value);
    setResult(null); // Clear the result when the percentage is changed
  };

  const calculatePercentage = () => {
    const base = parseFloat(baseNumber);
    const percent = parseFloat(percentage);

    if (!isNaN(base) && !isNaN(percent)) {
      const resultValue = (base * percent) / 100;
      setResult(resultValue.toFixed(2));
    } else {
      setResult(null);
    }
  };

  return (
    <Content
      title="Precentage Calculator"
      description="Streamline percentage calculations with our versatile calculator.
      Effortlessly solve any percentage-related task with precision."
    >
      <div className="top-slice-container">
        <Breadcrumb />
        <h1>Percentage Calculator</h1>
        <div>
          <label>Base Number:</label>
          <input
            className="calculator-input"
            type="number"
            value={baseNumber}
            onChange={handleBaseNumberChange}
          />
        </div>
        <div>
          <label>Percentage:</label>
          <input
            className="calculator-input"
            type="number"
            value={percentage}
            onChange={handlePercentageChange}
          />
        </div>
        <button className="calculate-button" onClick={calculatePercentage}>
          Calculate
        </button>
        {result !== null && (
          <div>
            <h3>Result:</h3>
            <p>{result}</p>
          </div>
        )}
      </div>

      <div className="info-paragraphs margin-auto">
        <h2>Basic Percentage Calculations:</h2>
        <p>
          Basic percentage calculations are fundamental mathematical operations
          that involve expressing a portion of a whole as a fraction of 100. The
          concept of percentages is prevalent in everyday life, from shopping
          discounts to financial transactions and academic grading. Calculating
          percentages often involves finding what portion a particular value
          represents in relation to the whole.
        </p>
        <p>
          For instance, when determining a percentage of a given number, you are
          essentially expressing a part of that number as a percentage of the
          whole. The formula for calculating a percentage is straightforward:
          (Part/Whole) * 100. This simple formula is the foundation for a wide
          range of applications, making it an essential skill in mathematics.
        </p>
        <p>
          Percentage increase or decrease is another common calculation, often
          used to analyze changes over time. To calculate the percentage change,
          you compare the difference between two values to the original value
          and express it as a percentage. This is particularly useful in fields
          such as finance, economics, and statistics for tracking trends,
          growth, or decline in various scenarios.
        </p>
        <p>
          Understanding basic percentage calculations is crucial not only in
          mathematical contexts but also in real-world scenarios where these
          calculations are applied. Whether determining the savings from a sale,
          analyzing data trends, or computing grades in education, the ability
          to work with percentages is a valuable skill that empowers individuals
          to make informed decisions in diverse situations.
        </p>

        <h2>Discounts and Markups:</h2>

        <h3>Discounts:</h3>
        <p>
          In the retail sector, discounts are a common strategy to attract
          customers and boost sales. A percentage calculator simplifies the
          process of determining the discounted price of an item. For instance,
          if an item is on sale with a 20% discount, the calculator can swiftly
          compute the reduced price by subtracting 20% from the original cost.
          Consumers benefit from this as it allows them to make informed
          decisions about their purchases, while retailers can strategically set
          discounts to manage inventory and stimulate demand.
        </p>
        <h3>Markups:</h3>
        <p>
          Conversely, markups are used by businesses to increase the selling
          price of a product or service. The percentage calculator aids in
          determining the markup percentage, ensuring that the desired profit
          margin is achieved. If a business incurs costs associated with
          production or acquisition, knowing how to apply a markup percentage is
          essential for establishing competitive yet profitable pricing
          strategies. This skill is vital in various industries, including
          manufacturing, retail, and services.
        </p>

        <p>
          In both cases, percentage calculators simplify what could be complex
          calculations, making it easier for individuals and businesses to set
          prices, make purchasing decisions, and manage financial aspects
          effectively. The ability to swiftly and accurately calculate discounts
          and markups contributes to informed decision-making and financial
          success in the dynamic world of commerce.
        </p>

        <h2>Interest Rates</h2>
        <p>
          Interest rates are a critical component of the financial world,
          influencing various aspects of borrowing, lending, and investment. A
          percentage calculator is an invaluable tool in navigating the
          complexities of interest rate calculations, making it easier for
          individuals and businesses to understand the costs and returns
          associated with financial transactions.
        </p>

        <h3>Borrowing and Loans</h3>

        <p>
          When individuals or businesses seek loans, interest rates determine
          the cost of borrowing money. A percentage calculator aids in computing
          the total interest payable over the loan term, allowing borrowers to
          assess the affordability of the loan and make informed decisions. For
          example, in a fixed-rate mortgage, the calculator helps determine
          monthly payments based on the loan amount and interest rate.
        </p>

        <h3>Investments</h3>
        <p>
          Interest rates play a pivotal role in the world of investments.
          Investors often use percentage calculators to estimate potential
          returns on investment vehicles like bonds, where interest payments
          contribute to overall yield. Calculating interest earnings allows
          investors to gauge the profitability of their portfolios and make
          strategic investment decisions based on prevailing interest rate
          conditions.
        </p>

        <h3>Consumer Finance</h3>
        <p>
          In everyday financial transactions, interest rates are encountered in
          credit cards, auto loans, and other consumer financing options. A
          percentage calculator aids consumers in understanding the true cost of
          credit, including interest charges, and comparing different financing
          alternatives to make informed choices aligned with their financial
          goals.
        </p>

        <p>
          In essence, interest rates are a pervasive force in the financial
          landscape, and the use of percentage calculators enhances financial
          literacy by simplifying the often intricate calculations associated
          with interest rates. Whether planning for a mortgage, evaluating
          investment opportunities, or understanding the impact of interest on
          savings, the calculator empowers individuals to navigate the financial
          terrain with greater confidence and clarity.
        </p>

        <h2>Percentiles</h2>
        <p>
          Percentiles represent specific points within a dataset that divide it
          into 100 equal parts. Each percentile indicates the percentage of data
          points below that particular value. For example, the 50th percentile
          (median) is the value below which 50% of the data falls. A percentage
          calculator simplifies the computation of these points, making it
          easier to identify central tendencies and variations in the data.
        </p>

        <h3>Applications in Standardized Testing</h3>
        <p>
          In standardized testing, percentiles are commonly used to compare an
          individual's performance to a larger population. A percentage
          calculator assists in determining the percentile rank of a test score,
          indicating the percentage of test-takers who scored below that
          particular individual. This information is valuable for understanding
          how an individual's performance compares to a broader group.
        </p>

        <h3>Income Distribution and Economics</h3>
        <p>
          Percentiles are also utilized in economics to analyze income
          distribution. Calculating income percentiles helps policymakers and
          researchers understand the distribution of wealth within a population.
          This information is crucial for designing effective economic policies
          and addressing issues of income inequality.
        </p>

        <h2>Data Representation</h2>
        <p>
          Percentage calculators are frequently used to create pie charts, bar
          graphs, and other visual representations that highlight the
          proportional distribution of data. These visuals often use percentages
          to illustrate the contribution of each component to the whole. For
          example, a pie chart might depict the market share of different
          products, with each slice representing a percentage of the total
          market.
        </p>
      </div>
    </Content>
  );
};
