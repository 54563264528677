import React, { useState } from "react";
import { Content } from "../../Content";
import { StatSigDataCalculator } from "../../components/StatSigDataCalculator";
import { StatSigCalculator } from "../../components/StatSigCalculator";
import Breadcrumb from "../../components/Breadcrumb";

export const StatisticalSignificanceCalculatorPage = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <Content
      title="Statistical Significance Calculator"
      description="Unlock insights with our statistical significance calculator.
      Analyze data confidently with accurate statistical tests."
    >
      <div className="top-slice-container">
        <Breadcrumb />
        <h1>Statistical Significance Calculator</h1>
        <div className="tab-container">
          <button
            className={activeTab === 1 ? "tab-button active" : "tab-button"}
            onClick={() => handleTabClick(1)}
          >
            Calculator
          </button>
          <button
            className={activeTab === 2 ? "tab-button active" : "tab-button"}
            onClick={() => handleTabClick(2)}
          >
            Raw Data
          </button>
        </div>
        <div className="tab-content-container">
          {activeTab === 1 && (
            <div className="tab-content center">
              <StatSigCalculator />
            </div>
          )}
          {activeTab === 2 && (
            <div className="tab-content center">
              <StatSigDataCalculator />
            </div>
          )}
        </div>
      </div>

      <div className="info-paragraphs margin-auto">
        <h2>Historical Development</h2>
        <p>
          The concept of statistical significance traces its roots to the early
          20th century, with pioneers like Ronald A. Fisher and Jerzy Neyman
          contributing to its development. Fisher introduced the notion of null
          hypothesis testing, laying the foundation for hypothesis testing
          methodologies. Over time, statistical significance became a
          cornerstone in scientific research, allowing researchers to make
          informed decisions based on data analysis.
        </p>

        <h2>Nuances of Hypothesis Testing</h2>
        <p>
          Statistical significance is often employed in hypothesis testing,
          where researchers aim to draw conclusions about a population based on
          sample data. The process involves setting up a null hypothesis and an
          alternative hypothesis, collecting data, and using statistical tests
          to determine if the observed effects are likely due to chance or if
          they are statistically significant. It's crucial to consider factors
          such as sample size, effect size, and the chosen significance level
          (alpha) when interpreting results.
        </p>

        <h2>Common Statistical Tests</h2>
        <p>
          Various statistical tests are utilized to assess significance,
          depending on the nature of the data and research question. The t-test,
          chi-square test, ANOVA, and regression analysis are among the commonly
          employed methods. Each test comes with its assumptions and
          limitations, and choosing the appropriate test is essential for
          obtaining accurate and reliable results.
        </p>

        <h2>Practical Applications</h2>
        <p>
          Statistical significance plays a crucial role in a wide range of
          fields, including medicine, social sciences, and business. In clinical
          trials, researchers assess whether a new treatment has a statistically
          significant effect compared to a control group. Social scientists use
          statistical significance to validate theories, and businesses apply it
          in market research to make data-driven decisions. As technology
          advances, statistical methods continue to evolve, providing more
          sophisticated tools for analyzing complex datasets.
        </p>
      </div>
    </Content>
  );
};
