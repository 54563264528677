import React, { useState } from "react";
import { Content } from "../../Content";
import Breadcrumb from "../../components/Breadcrumb";
import "../../styles/calculator.css";
import "../../styles/mean-median-mode-range-calculator.css";

export const MeanMedianModeRangeCalculatorPage = () => {
  const [numbers, setNumbers] = useState("");
  const [mean, setMean] = useState(null);
  const [median, setMedian] = useState(null);
  const [mode, setMode] = useState(null);
  const [range, setRange] = useState(null);

  const handleNumbersChange = (e) => {
    setNumbers(e.target.value);
    setMean(null); // Clear previous results when numbers change
    setMedian(null);
    setMode(null);
    setRange(null);
  };

  const calculateStats = () => {
    const numArray = numbers
      .split(",")
      .map((num) => parseFloat(num.trim()))
      .filter((num) => !isNaN(num));

    if (numArray.length > 0) {
      const sortedArray = numArray.slice().sort((a, b) => a - b);

      // Calculate mean
      const meanValue =
        numArray.reduce((sum, num) => sum + num, 0) / numArray.length;
      setMean(meanValue.toFixed(2));

      // Calculate median
      const middleIndex = Math.floor(sortedArray.length / 2);
      const medianValue =
        sortedArray.length % 2 === 0
          ? (sortedArray[middleIndex - 1] + sortedArray[middleIndex]) / 2
          : sortedArray[middleIndex];
      setMedian(medianValue.toFixed(2));

      // Calculate mode
      const countMap = {};
      let maxCount = 0;
      let modes = [];

      numArray.forEach((num) => {
        countMap[num] = (countMap[num] || 0) + 1;

        if (countMap[num] > maxCount) {
          maxCount = countMap[num];
          modes = [num];
        } else if (countMap[num] === maxCount) {
          modes.push(num);
        }
      });

      setMode(modes.join(", "));

      // Calculate range
      const rangeValue = sortedArray[sortedArray.length - 1] - sortedArray[0];
      setRange(rangeValue.toFixed(2));
    }
  };

  return (
    <Content
      title="Mean, Median, Mode and Range Calculator"
      description="Effortlessly calculate statistical measures with our Mean, Median, Mode, and Range Calculator.
      Make data analysis simple."
    >
      <div className="top-slice-container">
        <Breadcrumb />
        <h1>Mean, Median, Mode and Range Calculator</h1>
        <div className="mean-median-mode-range-calc-container">
          <div className="textarea-label">Numbers (comma-separated):</div>
          <div className="text-area-container">
            <textarea
              className="calculator-input text-area"
              type="text"
              value={numbers}
              onChange={handleNumbersChange}
            />
          </div>
        </div>
        <button className="calculate-button" onClick={calculateStats}>
          Calculate
        </button>
        {mean !== null && (
          <div>
            <h3>Mean:</h3>
            <p>{mean}</p>
          </div>
        )}
        {median !== null && (
          <div>
            <h3>Median:</h3>
            <p>{median}</p>
          </div>
        )}
        {mode !== null && (
          <div>
            <h3>Mode:</h3>
            <p>{mode}</p>
          </div>
        )}
        {range !== null && (
          <div>
            <h3>Range:</h3>
            <p>{range}</p>
          </div>
        )}
      </div>

      <div className="info-paragraphs margin-auto">
        <h2>Calculation Methods</h2>
        <h3>Mean (Average)</h3>
        <p>
          The mean, or average, is a measure of central tendency calculated by
          summing up all the values in a data set and dividing the sum by the
          total number of values. Mathematically, the mean is represented as (
          ∑n i=1 x) / n , where n is the number of values and i represents each
          individual value. The mean is sensitive to extreme values, as they can
          significantly influence the overall result. It is a widely used
          measure in various fields such as finance, science, and social
          sciences, providing a representative value for the data set.
        </p>
        <h3>Median</h3>
        <p>
          The median is the middle value of a data set when it is ordered from
          least to greatest. If the data set has an even number of values, the
          median is the average of the two middle values. To find the median,
          the data set is first arranged in ascending or descending order, and
          then the middle value or values are identified. The median is less
          affected by extreme values (outliers) compared to the mean, making it
          a robust measure of central tendency, especially for skewed
          distributions.
        </p>
        <h3>Mode</h3>
        <p>
          The mode is the value(s) in a data set that occur(s) most frequently.
          A data set may be unimodal (one mode), bimodal (two modes), or
          multimodal (more than two modes). Unlike the mean and median, the mode
          is not affected by the actual values of the data; it only considers
          their frequency. Calculating the mode involves identifying the
          value(s) with the highest frequency. In cases where no value is
          repeated, the data set is considered to have no mode.
        </p>
        <h3>Range</h3>
        <p>
          The range is a measure of variability and is calculated as the
          difference between the maximum and minimum values in a data set.
          Mathematically, it is represented as Range=Max-Min. The range provides
          a quick assessment of the spread or dispersion of values in the data
          set. However, it is sensitive to outliers, as extreme values can
          disproportionately influence the range. While simple to calculate, the
          range may not capture the full complexity of variability in a data
          set, leading to the use of more sophisticated measures like the
          interquartile range (IQR) for a more robust assessment.
        </p>

        <h2>Real-World Applications</h2>
        <h3>Mean</h3>
        <p>
          The mean, being a measure of central tendency, finds widespread
          application in various real-world scenarios. In finance, the mean is
          used to calculate average returns on investments or assess the average
          income of a population. In education, the mean represents the average
          score on a test or the average performance of students. Mean values
          are also employed in quality control to assess the average performance
          of manufacturing processes, ensuring consistency and reliability.
        </p>

        <h3>Median</h3>
        <p>
          The median, often considered a robust measure of central tendency, is
          particularly useful in real-world applications where extreme values or
          outliers can significantly impact the average. In household income
          distribution studies, for instance, the median income provides a
          better representation of the typical income level as it is less
          affected by extremely high or low incomes. In transportation planning,
          the median is used to gauge average commute times, offering insights
          into the typical travel experience.
        </p>

        <h3>Mode</h3>
        <p>
          The mode, representing the most frequently occurring value in a
          dataset, has practical applications in various fields. In
          manufacturing, it can help identify the most common defect or issue in
          a production line, allowing for targeted improvements. In market
          research, identifying the mode in customer preferences can guide
          product development strategies. The mode is also valuable in
          healthcare to pinpoint the most prevalent symptoms or conditions
          within a population, aiding in resource allocation and public health
          planning.
        </p>

        <h3>Range</h3>
        <p>
          The range, as a measure of variability, is employed in real-world
          applications to assess the spread of values within a dataset. In
          weather forecasting, for example, the range of daily temperatures
          provides a sense of how much the temperature is expected to fluctuate.
          In sports analytics, the range of scores can indicate the level of
          competitiveness in a game or the consistency of a player's
          performance. In finance, the range of stock prices over a given period
          helps investors understand the volatility of a particular asset.
        </p>

        <h2>Comparing Mean, Median, Mode and Range</h2>
        <h3>Mean</h3>
        <p>
          The mean is a measure of central tendency calculated by summing up all
          values in a dataset and dividing by the number of values. It is
          sensitive to extreme values, making it prone to skewness in the data.
          While the mean provides a precise representation of the average, it
          may be influenced by outliers, resulting in a value that does not
          accurately reflect the typical data point. However, for symmetrically
          distributed data without outliers, the mean is a powerful and
          intuitive measure, particularly useful for calculating expected
          values.
        </p>
        <h3>Median</h3>
        <p>
          The median is the middle value in a sorted dataset. It is less
          affected by extreme values than the mean, making it a robust measure
          of central tendency. The median is particularly useful for skewed
          distributions or datasets with outliers, as it remains unaffected by
          values at the extremes. However, the median loses some precision
          compared to the mean, as it does not consider the actual values but
          focuses on their relative position. It is especially valuable when
          describing the central tendency of ordinal or interval data.
        </p>
        <h3>Mode</h3>
        <p>
          The mode represents the most frequently occurring value in a dataset.
          It is robust against outliers and skewness, making it suitable for
          skewed distributions. However, datasets may have no mode or multiple
          modes, and in cases of multimodality, the mode may not accurately
          capture the overall central tendency. The mode is particularly
          intuitive for nominal data, where identifying the most common category
          provides valuable insights, such as the preferred product in a market
          or the most frequent diagnosis in healthcare.
        </p>
        <h3>Range</h3>
        <p>
          The range measures the variability within a dataset by calculating the
          difference between the maximum and minimum values. It offers a simple
          assessment of spread but is highly sensitive to outliers. While the
          range provides a quick overview of the data's extent, it may not be
          representative of the distribution's complexity, and extreme values
          can distort its interpretation. For a more robust measure of
          variability, the interquartile range (IQR) is often preferred as it is
          less affected by outliers and provides a better sense of the central
          spread.
        </p>

        <h2>Skewness and Outliers</h2>
        <h3>Mean</h3>
        <p>
          The mean is highly sensitive to outliers, which are values that
          deviate significantly from the rest of the data. When a data set has
          outliers, the mean tends to be pulled towards these extreme values,
          causing it to no longer accurately represent the central tendency of
          the majority of the data. As a result, mean may not be the best
          measure of central tendency for skewed distributions or datasets with
          outliers, as it can be heavily influenced by these extreme values.
        </p>
        <h3>Median</h3>
        <p>
          The median is less affected by skewness and outliers compared to the
          mean. Since the median is based on the middle value of a sorted
          dataset, extreme values do not exert as much influence. In cases of
          positively or negatively skewed distributions, the median remains
          robust and provides a better reflection of the central position of the
          majority of the data. This makes the median a preferred measure when
          dealing with skewed datasets or those prone to outliers.
        </p>
        <h3>Mode</h3>
        <p>
          The mode, being based solely on frequency, is not directly influenced
          by outliers. It focuses on the values that occur most frequently in
          the dataset. However, in the presence of outliers, the mode may not
          accurately represent the central tendency, especially if the outliers
          cause the distribution to become bimodal or multimodal. In such cases,
          the mode might not provide a clear indication of the central peak or
          concentration of values.
        </p>
        <h3>Range</h3>
        <p>
          The range is highly sensitive to outliers, as it is calculated as the
          difference between the maximum and minimum values in a dataset. If
          there are extreme values in the dataset, they can disproportionately
          affect the range, providing an exaggerated measure of variability. For
          datasets with outliers, it is often more appropriate to use measures
          of variability that are less influenced by extreme values, such as the
          interquartile range (IQR).
        </p>
      </div>
    </Content>
  );
};
