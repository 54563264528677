import React, { useState } from "react";
import '../styles/calculator.css'
import '../styles/contact-us-form.css'

export const ContactUsForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");
  const [contacted, setContacted] = useState("");

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {

      const response = fetch('https://yc5ez9aoni.execute-api.us-east-2.amazonaws.com/dev/api/email', {
        method: 'POST',
        headers: {
          "x-api-key": 'gPufTmMIdT2ZzK0x0woc81iu6WNi8UGMau4Rxv6H'
        },
        body: {
          name: name,
          fromEmail: email,
          message: description
        }
      })

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();

      setContacted(true)
    } catch (error) {
      console.log(error)
      setError(error.message)
    }
  };

  return (
    <>
      <div className="contact-us-form">
        <form onSubmit={handleFormSubmit}>
          <div>
            <input
              className="calculator-input"
              placeholder="Your Name..."
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div>
            <input
              className="calculator-input"
              placeholder="Email..."
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <textarea
              className="calculator-input"
              placeholder="description..."
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>
          <button className="calculate-button" type="submit">Submit</button>
        </form>
      </div>
    </>
  );
};
