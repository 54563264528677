import React, { useState } from "react";
import { Content } from "../../Content";
import Breadcrumb from "../../components/Breadcrumb";
import "../../styles/calculator.css";

export const MortgageCalculatorPage = () => {
  const [homePrice, setHomePrice] = useState("");
  const [downPayment, setDownPayment] = useState("");
  const [loanTerm, setLoanTerm] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [monthlyPaymentResult, setMonthlyPaymentResult] = useState(null);

  const handleHomePriceChange = (e) => {
    setHomePrice(e.target.value);
  };

  const handleDownPaymentChange = (e) => {
    setDownPayment(e.target.value);
  };

  const handleLoanTermChange = (e) => {
    setLoanTerm(e.target.value);
  };

  const handleInterestRateChange = (e) => {
    setInterestRate(e.target.value);
  };

  const calculateMoMortgage = () => {
    var monthlyInterestRateDecimal = interestRate / 100 / 12;
    var loanTermMonths = loanTerm * 12;

    var numerator =
      homePrice *
      monthlyInterestRateDecimal *
      ((1 + monthlyInterestRateDecimal) ^ loanTermMonths);

    var denominator = ((1 + monthlyInterestRateDecimal) ^ loanTermMonths) - 1;
    var monthlyPayment = (numerator / denominator).toFixed(2);

    setMonthlyPaymentResult(monthlyPayment);
  };

  return (
    <Content
      title="Mortgage Calculator"
      description="Explore the web's premier mortgage calculator to estimate monthly payments,
      including home price, down payment and more. Simplify financial planning with our suite of online tools."
    >
      <div className="top-slice-container">
        <Breadcrumb />
        <h1>Mortgage Calculator</h1>
        <div>
          <label>Home Price ($): </label>
          <input
            className="calculator-input"
            type="number"
            value={homePrice}
            onChange={handleHomePriceChange}
          />
        </div>
        <div>
          <label>Down Payment ($): </label>
          <input
            className="calculator-input"
            type="number"
            value={downPayment}
            onChange={handleDownPaymentChange}
          />
        </div>
        <div>
          <label>Loan Term (years): </label>
          <input
            type="number"
            className="calculator-input"
            value={loanTerm}
            onChange={handleLoanTermChange}
          />
        </div>
        <div>
          <label>Interest Rate (%): </label>
          <input
            type="number"
            className="calculator-input"
            value={interestRate}
            onChange={handleInterestRateChange}
          />
        </div>
        <button className="calculate-button" onClick={calculateMoMortgage}>
          Calculate
        </button>
        {monthlyPaymentResult !== null && (
          <div>
            <h3>Monthly Payment:</h3>
            <p>${monthlyPaymentResult}</p>
          </div>
        )}
      </div>

      <div className="info-paragraphs margin-auto">
        <h3>Loan Amount and Down Payment</h3>
        <p>
          The loan amount and down payment are crucial components in the realm
          of mortgage calculations, playing a significant role in determining
          the financial terms of a home loan. Mortgage calculators allow
          prospective homebuyers to explore the relationship between these two
          elements, aiding in the assessment of affordability and financial
          planning.
        </p>
        <p>
          The loan amount is essentially the sum of money borrowed to purchase a
          home. Mortgage calculators enable you to input the home price and
          desired down payment percentage, subsequently calculating the loan
          amount. This feature is instrumental in helping individuals understand
          the magnitude of the financial commitment they are undertaking. You
          can experiment with various home prices and down payment scenarios to
          find a loan amount that aligns with their budgetary constraints and
          preferences.
        </p>
        <p>
          Down payment, on the other hand, represents the initial upfront
          payment made by the homebuyer. It is usually expressed as a percentage
          of the home's purchase price. Mortgage calculators allow you to input
          different down payment percentages, influencing the loan amount and
          subsequently impacting monthly mortgage payments. A higher down
          payment often results in a lower loan amount and can lead to reduced
          interest payments over the life of the loan
        </p>
        <h2>Interest Rates</h2>
        <p>
          Interest rates are a pivotal factor influencing the overall cost of a
          mortgage, and mortgage calculators are indispensable tools for
          understanding their impact on monthly payments and the total amount
          paid over the life of the loan. These calculators typically allow you
          to input different interest rates, facilitating a comprehensive
          analysis of how fluctuations in these rates can affect the financial
          aspects of a mortgage.
        </p>
        <p>
          When using a mortgage calculator, individuals can experiment with
          varying interest rates to observe the corresponding adjustments in
          monthly payments. Higher interest rates generally lead to increased
          monthly payments, while lower rates may result in more affordable
          payments. This dynamic relationship highlights the importance of
          securing a favorable interest rate to minimize the financial burden
          associated with a mortgage.
        </p>
        <p>
          Moreover, mortgage calculators enable you to assess the long-term
          effects of interest rates on the total cost of the mortgage. By
          inputting different rates, borrowers can discern how seemingly small
          variations in interest can accumulate into substantial differences in
          the overall repayment amount. This information is invaluable when
          comparing loan options and seeking the most financially advantageous
          mortgage terms.
        </p>

        <h2>Loan Term</h2>
        <p>
          The loan term is a fundamental aspect of mortgage financing,
          representing the duration over which borrowers commit to repaying
          their home loan. Mortgage calculators play a crucial role in helping
          individuals assess the impact of different loan terms on their monthly
          payments, overall interest costs, and financial flexibility.
        </p>

        <p>
          When using a mortgage calculator, users can experiment with varying
          loan terms, typically ranging from 10 to 30 years or more. Shorter
          loan terms, such as 15 years, often come with higher monthly payments
          but result in significantly lower total interest paid over the life of
          the loan. In contrast, longer loan terms, like 30 years, may offer
          lower monthly payments but result in higher overall interest costs.
          Mortgage calculators empower individuals to strike a balance between
          managing their monthly budget and optimizing the long-term financial
          implications of their mortgage.
        </p>

        <p>
          Choosing the right loan term involves considering individual financial
          goals and circumstances. Some may prioritize the goal of owning their
          home outright sooner and are willing to commit to higher monthly
          payments with a shorter loan term. Others may opt for longer loan
          terms to achieve more manageable monthly payments, providing greater
          financial flexibility for other investments or expenses. Mortgage
          calculators serve as valuable tools in this decision-making process,
          enabling borrowers to align their loan term choices with their overall
          financial strategy.
        </p>

        <h2>Extra Payments</h2>

        <p>
          Extra payments represent a powerful strategy for homeowners looking to
          accelerate the repayment of their mortgage and reduce the overall
          interest costs. Mortgage calculators provide a dynamic platform for
          individuals to explore the impact of making additional payments on
          their loan, helping them understand how these extra contributions can
          lead to substantial savings in both time and money.
        </p>

        <h2>Refinancing</h2>
        <p>
          Refinancing a mortgage is a financial strategy that involves replacing
          an existing loan with a new one, usually to secure better terms, lower
          interest rates, or adjust the loan term. Mortgage calculators play a
          pivotal role in helping homeowners evaluate the potential benefits of
          refinancing, offering a comprehensive analysis of how changes in
          interest rates and loan terms can impact their financial situation.
        </p>

        <p>
          When considering refinancing options, individuals can input different
          interest rates, loan amounts, and terms into mortgage calculators to
          compare the potential savings or costs associated with the new loan.
          This allows borrowers to assess whether refinancing makes financial
          sense by analyzing the potential reduction in monthly payments or the
          overall interest paid over the life of the loan.
        </p>

        <h2>Closing Costs</h2>
        <p>
          Closing costs refer to the various fees and expenses associated with
          the finalization of a real estate transaction, typically occurring
          when a homebuyer completes the purchase of a property and secures a
          mortgage. These costs are incurred in addition to the purchase price
          of the home and are essential for completing the legal and financial
          aspects of the homebuying process.
        </p>

        <p>
          Closing costs can vary widely and are influenced by factors such as
          the location of the property, the price of the home, and the type of
          mortgage. While the specific fees can differ, common components of
          closing costs include Loan Origination Fees, Appraisal Fees, Title
          Insurance, Attorney Fees and Home Inspection Fees.
        </p>

        <p>
          It's important for homebuyers to be aware of closing costs and budget
          for them when planning a home purchase. The total amount can range
          from 2% to 5% of the home's purchase price, though this can vary.
          Buyers may negotiate with the seller to cover some of these costs, and
          in some cases, lenders may offer programs that assist with closing
          costs. Understanding and accounting for closing costs is a crucial
          aspect of the overall financial planning involved in buying a home.
        </p>
      </div>
    </Content>
  );
};
