import React, { useState } from "react";
import { tTestTwoSample } from "simple-statistics";

export const StatSigCalculator = () => {
  const [controlConversions, setControlConversions] = useState(0);
  const [controlVisitors, setControlVisitors] = useState(0);
  const [experimentalConversions, setExperimentalConversions] = useState(0);
  const [experimentalVisitors, setExperimentalVisitors] = useState(0);
  const [confidenceLevel, setConfidenceLevel] = useState(95);
  const [answer, setAnswer] = useState("");

  const calculateStatisticalSignificance = () => {
    const controlData = Array(Number(controlVisitors))
      .fill(1)
      .concat(
        Array(Number(controlVisitors) - Number(controlConversions)).fill(0)
      );
    const experimentalData = Array(Number(experimentalVisitors))
      .fill(1)
      .concat(
        Array(
          Number(experimentalVisitors) - Number(experimentalConversions)
        ).fill(0)
      );

    const { pValue } = tTestTwoSample(controlData, experimentalData);

    // Compare p-value with significance level
    const isStatisticallySignificant = pValue < 1 - confidenceLevel / 100;

    return isStatisticallySignificant;
  };

  const handleCalculateClick = () => {
    const isStatSig = calculateStatisticalSignificance();
    isStatSig
      ? setAnswer("Statistically Significant")
      : setAnswer("Not Statistically Significant");
  };

  return (
    <div>
      <label>
        Control Visitors:
        <input
          className="calculator-input"
          type="number"
          value={controlVisitors}
          onChange={(e) => setControlVisitors(e.target.value)}
        />
      </label>
      <br />
      <label>
        Control Conversions:
        <input
          className="calculator-input"
          type="number"
          value={controlConversions}
          onChange={(e) => setControlConversions(e.target.value)}
        />
      </label>
      <br />
      <label>
        Experimental Visitors:
        <input
          className="calculator-input"
          type="number"
          value={experimentalVisitors}
          onChange={(e) => setExperimentalVisitors(e.target.value)}
        />
      </label>
      <br />
      <label>
        Experimental Conversions:
        <input
          className="calculator-input"
          type="number"
          value={experimentalConversions}
          onChange={(e) => setExperimentalConversions(e.target.value)}
        />
      </label>
      <br />
      <label>
        Confidence Level:
        <input
          className="calculator-input"
          type="number"
          value={confidenceLevel}
          onChange={(e) => setConfidenceLevel(e.target.value)}
        />
      </label>
      <br />
      <button className="calculate-button" onClick={handleCalculateClick}>Calculate</button>
      {answer && (
        <div>
          <h3>Result: {answer}</h3>
        </div>
      )}
    </div>
  );
};
