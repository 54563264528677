import { Content } from "../Content";
import { CategoryLinks } from "../components/CategoryLinks";
import FinancialCalculatorList from "./financial/financial-calculators.json";

export const FinancialPage = () => {
  return (
    <Content
      title="Financial Calculators"
      description="Access a variety of financial calculators for smarter decisions.
      Simplify your finances with our comprehensive tool collection.">
      <div className="link-page-padding">
        <h1>Financial Calculators</h1>
        <CategoryLinks calcLinks={FinancialCalculatorList} />
      </div>
    </Content>
  );
};
