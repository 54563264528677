import { Content } from "../Content";
import { ContactUsForm } from "../components/ContactUsForm";

export const AboutUsPage = () => {
  return (
    <Content
      title="About Us"
      description="Discover our story and mission.
      Learn more about who we are and what drives us to serve you better."
    >
      <div className="info-paragraphs margin-auto">
        <h1>About Us</h1>
        <p>
          Welcome to CalcuCrafter, your go-to destination for free and powerful
          online calculators! Established in 2023, we are a group of
          enthusiastic software engineers driven by the passion for providing
          indispensable tools to people everywhere.
        </p>
        <p>
          At CalcuCrafter, we believe that everyone should have access to
          essential tools that simplify complex calculations. That's why we've
          dedicated ourselves to creating a collection of free, user-friendly
          calculators that cater to a variety of needs.
        </p>
        <h2>Frequently Asked Questions</h2>
        <p>
          <b>
            Q: Can I link from my website to a calculator on CalcuCrafter.com?
          </b>
          <br />
          A: Yes, please feel free to link to any page on CalcuCrafter.com.
        </p>
        <p>
          <b>Q: How should I send suggestions or report problems?</b>
          <br />
          A: We value and encourage your suggestions and bug reports. Feel free
          to reach out to us using the form below. If your feedback pertains to
          a specific calculator, kindly provide the calculator's URL along with
          the parameters you used. Your input helps us improve our services.
        </p>
        <p>
          <b>Q: Do you have an API for the calculators?</b>
          <br />
          A: While we currently don't offer a publicly accessible API, if you
          have a specific requirement please feel free to reach out. We'll be
          happy to discuss and explore the possibility of collaborating with you
          to create a tailored solution.
        </p>
        <ContactUsForm />
        <h2 id="privacy-policy">Privacy Policy</h2>
        <p>
          Calcucrafter is committed to protecting your privacy. This Privacy
          Policy outlines how we collect, use, and disclose information when you
          use our website and services (the "Service"). By using Calcucrafter,
          you consent to the practices described in this policy.
        </p>
        <h3>1. Information We Collect</h3>
        <p>
          1.1 Personal Information: When you use our calculators and tools, we
          do not collect personally identifiable information unless you
          voluntarily provide it. If you choose to create a user account, we may
          collect your name, email address, and other relevant details. 1.2
          Usage Information: We collect non-personal information about your
          interactions with our website, such as the pages visited, time spent
          on the site, and the type of device used.
        </p>
        <h3>2. How We Use Your Information</h3>
        <p>
          2.1 Personal Information: We use the personal information you provide
          to deliver the services you request. If you have a user account, we
          may use your email address to communicate with you regarding your
          account and to provide updates and information about our services. 2.2
          Usage Information: We use non-personal information to analyze user
          behavior and improve the functionality and content of our website. 3.
          Cookies and Similar Technologies Calcucrafter may use cookies and
          similar technologies to enhance your user experience. You can control
          the use of cookies through your browser settings.
        </p>
        <h3>4. Data Security</h3>
        <p>
          We take reasonable steps to protect your information from unauthorized
          access or disclosure. However, please be aware that no data
          transmission over the internet is entirely secure, and we cannot
          guarantee the security of information.
        </p>
        <h3>5. Third-Party Services</h3>
        Calcucrafter may use third-party services, such as analytics tools, that
        may collect information about your use of our website. These third
        parties have their own privacy policies, and we encourage you to review
        them.
        <h3>6. Children's Privacy</h3>
        <p>
          Calcucrafter does not knowingly collect personal information from
          children under the age of 13. If you believe that we have
          inadvertently collected such information, please contact us, and we
          will take steps to delete it.
        </p>
        <h3>7. Changes to Privacy Policy</h3>
        <p>
          Calcucrafter reserves the right to update or modify this Privacy
          Policy at any time. We will notify you of any changes by posting the
          updated policy on this page.
        </p>
        <h3>8. Contact Us</h3>
        <p>
          If you have any questions or concerns about our Privacy Policy, please
          contact us at jcconnol4@gmail.com. By using Calcucrafter, you
          acknowledge that you have read, understood, and agree to be bound by
          this Privacy Policy.
        </p>
        <h2 id="terms-of-use">Terms of Service</h2>
        Last Updated: 01/13/2024
        <p>
          Welcome to Calcucrafter! These Terms of Service outline the rules and
          regulations for the use of the Calcucrafter website (the "Service").
          By accessing this website, you agree to comply with and be bound by
          these terms. If you disagree with any part of these terms, please do
          not use our Service.
        </p>
        <h3>Acceptance of Terms</h3>
        <p>
          By using Calcucrafter, you agree to abide by these Terms of Service
          and any additional terms and conditions that may apply to specific
          sections of the website or to products and services available through
          Calcucrafter.
        </p>
        <h3>Use of Service</h3>
        <p>
          Calcucrafter provides free online calculators and tools for your
          convenience. You may use the calculators and tools for personal,
          non-commercial use. You are prohibited from using the Service for any
          unlawful purpose or to solicit others to perform illegal acts.
        </p>
        <h3>Intellectual Property</h3>
        <p>
          The content, design, and functionality of Calcucrafter are the
          intellectual property of Calcucrafter and are protected by copyright
          and other intellectual property laws. You may not reproduce, modify,
          distribute, or republish any content from this website without prior
          written consent from Calcucrafter.
        </p>
        <h3>Limitation of Liability</h3>
        <p>
          Calcucrafter is provided "as is" without any warranties or guarantees.
          While we strive to provide accurate and up-to-date information, we do
          not warrant the accuracy or completeness of the content on our
          website. In no event shall Calcucrafter be liable for any direct,
          indirect, incidental, consequential, or special damages arising out of
          or in any way connected with the use of our Service.
        </p>
        <h3>Privacy</h3>
        <p>
          Calcucrafter is committed to protecting your privacy. Please review
          our Privacy Policy to understand how we collect, use, and disclose
          information.
        </p>
        <h3>Changes to Terms of Service</h3>
        <p>
          Calcucrafter reserves the right to update or modify these Terms of
          Service at any time without prior notice. Your continued use of the
          Service after any changes signifies your acceptance of the revised
          terms.
        </p>
        <h3>Governing Law</h3>
        <p>
          These Terms of Service are governed by and construed in accordance
          with the laws of Franklin, Tennessee, and you agree to submit to the
          exclusive jurisdiction of the courts located in Franklin, Tennessee
          for the resolution of any disputes. By using Calcucrafter, you
          acknowledge that you have read, understood, and agree to be bound by
          these Terms of Service. If you have any questions or concerns, please
          contact us at jcconnol4@gmail.com.
        </p>
      </div>
    </Content>
  );
};
