import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";

const BreadcrumbComponent = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const formatText = (text) => {
    return text
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <Breadcrumb>
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
        Home
      </Breadcrumb.Item>
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;
        return (
          <>
            &gt;&nbsp;
            <Breadcrumb.Item
              key={routeTo}
              linkAs={isLast ? undefined : Link}
              linkProps={{ to: routeTo }}
            >
              {formatText(name)}
            </Breadcrumb.Item>
          </>
        );
      })}
    </Breadcrumb>
  );
};

export default BreadcrumbComponent;
