import { Content } from "../../Content";
import React, { useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import "../../styles/calculator.css";

export const AgeCalculatorPage = () => {
  const [startingDate, setStartingDate] = useState("");
  const [endingDate, setEndingDate] = useState("");
  const [ageResult, setAgeResult] = useState(null);

  const calculateAge = () => {
    if (!startingDate || !endingDate) {
      setAgeResult({
        years: 0,
        months: 0,
        days: 0,
      });

      return "";
    }

    let startDate = new Date(
      new Date(startingDate).toISOString().substr(0, 10)
    );
    if (!endingDate) {
      const newEndingDate = new Date().toISOString().substr(0, 10); // need date in YYYY-MM-DD format
      setEndingDate(newEndingDate);
    }
    let endDate = new Date(endingDate);
    if (startDate > endDate) {
      const swap = startDate;
      startDate = endDate;
      endDate = swap;
    }

    const startYear = startDate.getFullYear();
    const february =
      (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
        ? 29
        : 28;
    const daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    let yearDiff = endDate.getFullYear() - startYear;
    let monthDiff = endDate.getMonth() - startDate.getMonth();
    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }

    let dayDiff = endDate.getDate() - startDate.getDate();
    if (dayDiff < 0) {
      if (monthDiff > 0) {
        monthDiff--;
      } else {
        yearDiff--;
        monthDiff = 11;
      }
      dayDiff += daysInMonth[startDate.getMonth()];
    }

    setAgeResult({
      years: yearDiff,
      months: monthDiff,
      days: dayDiff,
    });
  };

  return (
    <Content
      title="Age Calculator"
      description="Utilize our complimentary age calculator to determine your age in
      various units: years, months, weeks, days, hours, minutes, and seconds, all derived
      from your date of birth."
    >
      <div className="top-slice-container">
        <Breadcrumb />
        <div className="info-paragraphs margin-auto">
        <h1>Age Calculator</h1>
        <p>
          This Age Calculator is designed to calculate the age or time span
          between two given dates. The resulting age will be presented in years,
          months, weeks, days, hours, minutes, and seconds.
        </p>
        </div>
        <label>
          Start Date:
          <input
            className="calculator-input"
            type="date"
            value={startingDate}
            onChange={(e) => setStartingDate(e.target.value)}
          />
        </label>
        <label>
          End Date:
          <input
            className="calculator-input"
            type="date"
            value={endingDate}
            onChange={(e) => setEndingDate(e.target.value)}
          />
        </label>
        <button className="calculate-button" onClick={calculateAge}>
          Calculate Age
        </button>

        {ageResult && (
          <div>
            <h2>Age Result:</h2>
            <p>Years: {ageResult.years}</p>
            <p>Months: {ageResult.months}</p>
            <p>Days: {ageResult.days}</p>
          </div>
        )}
      </div>

      <div className="info-paragraphs margin-auto">
        <p>
          The perception and calculation of age vary widely across different
          cultures, shaping how individuals understand and articulate their age.
          This age calculator operates on the prevalent age system commonly
          observed in Western societies, where age increments occur in tandem
          with each passing birthday. For instance, someone who has lived for 3
          years and 11 months is deemed to be 3 years old until their subsequent
          birthday, at which point they advance to 4 years old. This method is
          widely adopted in Western countries.
        </p>
        <p>
          Conversely, in some cultural contexts, age is calculated differently,
          with variations regarding the inclusion or exclusion of the current
          year. For instance, a person who is twenty years old may be considered
          to be in their twenty-first year of life, showcasing a different
          perspective on age. In traditional Chinese age reckoning, individuals
          are regarded as one year old upon birth, and their age progresses with
          the arrival of the Traditional Chinese New Year rather than their
          actual birthday. For example, a baby born a day before the Traditional
          Chinese New Year would be considered two years old just two days
          later, despite their mere two days of life.
        </p>
        <p>
          However, certain scenarios can lead to confusion, particularly when
          calculating months and days, especially when the starting date
          coincides with the end of a month. For instance, while we commonly
          interpret the period from February 20th to March 20th as one month,
          calculating the age from February 28th, 2022, to March 31st, 2022,
          presents two potential outcomes. If we consider February 28th to March
          28th as one month, the result is one month and three days. Conversely,
          if both February 28th and March 31st are treated as month-end dates,
          the result is simply one month. Both interpretations are valid, given
          the varying lengths of months. In our calculations, we adopt the
          former method for consistency, acknowledging potential confusion and
          striving to provide clarity in age determination. Similar complexities
          arise with dates like April 30th to May 31st and May 30th to June
          30th, highlighting the necessity for a standardized approach to age
          calculation
        </p>
      </div>
    </Content>
  );
};
