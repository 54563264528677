import { Content } from "../Content";
import { CategoryLinks } from "../components/CategoryLinks";
import DateTimeCalculatorList from "./date-time/date-time-calculators.json";

export const DateTimePage = () => {
  return (
    <Content
      title="Date and Time Calculators"
      description="Explore a variety of date and time calculators for precise scheduling.
      Simplify your planning with our comprehensive tool collection.">
      <div className="link-page-padding">
        <h1>Date and Time Calculators</h1>
        <CategoryLinks calcLinks={DateTimeCalculatorList} />
      </div>
    </Content>
  );
};
