import React, { useState } from "react";

export const StatSigDataCalculator = () => {
  const [dataInput, setDataInput] = useState("");
  const [result, setResult] = useState(null);

  const calculateSignificance = () => {
    // Split the input data into an array of numbers
    const numbers = dataInput.split(",").map(Number);

    // Perform a basic two-sample t-test (assuming independent samples)
    const tTestResult = performTTest(numbers);

    // Display the result
    setResult(tTestResult);
  };

  const performTTest = (data) => {
    // Perform the t-test logic here (you may want to use a statistical library for accuracy)
    // For simplicity, this example uses a basic calculation for illustration purposes
    const sampleMean = calculateMean(data);
    const sampleStandardDeviation = calculateStandardDeviation(data);
    const sampleSize = data.length;
    const tValue =
      (sampleMean - 0) / (sampleStandardDeviation / Math.sqrt(sampleSize));

    // For a two-tailed t-test with a 95% confidence level (alpha = 0.05)
    const criticalValue = 2.262; // You might want to look up the critical value from a t-table

    // Compare the t-value with the critical value
    const isSignificant = Math.abs(tValue) > criticalValue;

    return {
      tValue: tValue.toFixed(2),
      isSignificant,
    };
  };

  const calculateMean = (data) => {
    return data.reduce((sum, value) => sum + value, 0) / data.length;
  };

  const calculateStandardDeviation = (data) => {
    const mean = calculateMean(data);
    const squaredDifferences = data.map((value) => Math.pow(value - mean, 2));
    const variance =
      squaredDifferences.reduce((sum, value) => sum + value, 0) / data.length;
    return Math.sqrt(variance);
  };

  return (
    <div className="">
      <label>
        Input Data (comma-separated)
        <div>
          <textarea
            value={dataInput}
            onChange={(e) => setDataInput(e.target.value)}
            className="text-area calculator-input"
          />
        </div>
      </label>
      <button onClick={calculateSignificance} className="calculate-button">
        Calculate
      </button>

      {result && (
        <div>
          <h3>Result:</h3>
          <p>T-Value: {result.tValue}</p>
          <p>Is Significant: {result.isSignificant.toString()}</p>
        </div>
      )}
    </div>
  );
};
