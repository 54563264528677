import { Link } from 'react-router-dom'


export const CategoryLinks = ({ calcLinks }) => {
    return (
        <div className='calc-list'>
            {calcLinks.calculators.map(calcLink => (
                <div className='calc-list-item'>
                    <Link to={calcLink.path}>
                    {calcLink.name}
                    </Link>
                </div>
            ))}
        </div>
    )
}
