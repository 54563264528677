import React, { useState } from "react";
import { Content } from "../../Content";
import Breadcrumb from "../../components/Breadcrumb";

export const SalaryCalculatorPage = () => {
  const [annualSalary, setAnnualSalary] = useState("");
  const [bonuses, setBonuses] = useState("");
  const [deductions, setDeductions] = useState("");
  const [netSalary, setNetSalary] = useState(null);

  const calculateNetSalary = () => {
    const annualSalaryNumber = parseFloat(annualSalary) || 0;
    const bonusesNumber = parseFloat(bonuses) || 0;
    const deductionsNumber = parseFloat(deductions) || 0;

    const calculatedNetSalary =
      annualSalaryNumber + bonusesNumber - deductionsNumber;
    setNetSalary(calculatedNetSalary.toFixed(2));
  };

  const calculateDailySalary = () => {
    const daysInYear = 365; // Assuming 365 days in a year
    const dailySalary = netSalary / daysInYear;
    return dailySalary.toFixed(2);
  };

  const calculateMonthlySalary = () => {
    const monthsInYear = 12;
    const monthlySalary = netSalary / monthsInYear;
    return monthlySalary.toFixed(2);
  };

  return (
    <Content
      title="Salary Calculator"
      description="Calculate your earnings effortlessly with our salary calculator.
      Estimate income, taxes, and deductions accurately.">
      <div className="top-slice-container">
        <Breadcrumb />
        <h1>Salary Calculator</h1>
        <div>
          <label>
            Annual Salary:
            <input
              type="number"
              value={annualSalary}
              onChange={(e) => setAnnualSalary(e.target.value)}
              className="calculator-input"
            />
          </label>
        </div>
        <div>
          <label>
            Bonuses:
            <input
              type="number"
              value={bonuses}
              onChange={(e) => setBonuses(e.target.value)}
              className="calculator-input"
            />
          </label>
        </div>
        <div>
          <label>
            Deductions:
            <input
              type="number"
              value={deductions}
              onChange={(e) => setDeductions(e.target.value)}
              className="calculator-input"
            />
          </label>
        </div>

        <button onClick={calculateNetSalary} className="calculate-button">
          Calculate Net Salary
        </button>

        {netSalary !== null && (
          <div>
            <h3>Net Salary: ${netSalary}</h3>
            <p>Daily Salary: ${calculateDailySalary()}</p>
            <p>Monthly Salary: ${calculateMonthlySalary()}</p>
            <p>Yearly Salary: ${netSalary}</p>
          </div>
        )}
      </div>

      <div className="info-paragraphs margin-auto">
      <h2>Historical Perspectives</h2>
      <p>
        The concept of salaries has evolved significantly throughout history. In
        ancient civilizations, labor was often compensated with goods or
        services rather than a fixed monetary amount. The advent of currency and
        organized labor marked a shift, with wages becoming a standardized form
        of compensation. Industrialization further influenced salary structures,
        shaping the modern employment landscape.
      </p>

      <h2>Nuances of Compensation</h2>
      <p>
        Salaries encompass various components beyond the base pay, including
        bonuses, allowances, and benefits. Over time, nuances such as
        performance-based incentives, stock options, and retirement plans have
        emerged. These intricacies aim to align employee interests with
        organizational goals and enhance overall job satisfaction and loyalty.
      </p>

      <h2>Calculations and Formulas</h2>
      <p>
        The calculation of salaries involves multiple factors, with the basic
        formula being the sum of the base salary and additional compensation.
        Bonuses, commissions, and overtime pay contribute to the overall
        earnings. Deductions, including taxes and social security contributions,
        play a role in determining the net salary received by an individual.
      </p>

      <h2>Contemporary Trends</h2>
      <p>
        In the contemporary workforce, discussions around equal pay, salary
        transparency, and remote work arrangements are shaping salary
        structures. Technology has facilitated data-driven salary benchmarks,
        allowing companies to remain competitive in attracting and retaining
        talent. The gig economy has also introduced new models of compensation
        for freelancers and independent contractors.
      </p>
      </div>
    </Content>
  );
};
