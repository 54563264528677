import { Content } from "../Content";

export const NotFoundPage = () => {
  return (
    <Content
      title="Not Found"
      description="Oops, this page does not exist.">
      <div className="link-page-padding">
        <h1>Looks like this page doesn't exist!</h1>
        <p>
          Please Go to the <a href="https://www.calcucrafter.com">home</a> page!
        </p>
      </div>
    </Content>
  );
};
